import React, {useState} from "react";
import { useGlobal } from "../../redux/selectors";
import Modal from "../components/Modal";
import {
  FacebookSvg,
  InstagramSvg,
  TiktokSvg,
  YoutTubeSvg
} from "../components/Svgs";

function About() {
  const { language } = useGlobal();
  const [modal, setModal] = useState(false)
  return (
    <div className="bg-truck">
      {modal && <Modal truck="Xabar qoldirish" close={()=>setModal(false)}/>}
      <div className="p-[7vw] md:pt-[14vw] pt-[24vw] ">
        <div className="md:rounded-[1vw] rounded-[2vw] md:p-[1vw] p-[4vw] bg-[#ffffff10]">
          <h1 className="capitalize craftsemibold md:text-[2vw] text-[4vw] text-[#fff] p-[1vw] px-[2vw]">
            {language["formation"]}
          </h1>
          <div className="grid md:grid-cols-[60%_38%] gap-[2vw]">
            <div className="bg-[#ffffff10] md:p-[2vw] p-[4vw]  md:rounded-[1vw] rounded-[2vw] px-[4vw]">
              {/* <h1 className="text-[#fff] md:text-[1.4vw] text-[3.4vw] font-medium my-[1vw]">
                Description
              </h1> */}
              <p className="text-[#fff] md:text-[1vw] text-[3vw] font-thin text-justify">
                {language["aboutustextfull"]}
              </p>
            </div>
            <div className="bg-[#ffffff10] md:p-[2vw] p-[4vw] md:rounded-[1vw] rounded-[2vw]">
              <h1 className="text-[#fff] md:text-[1.4vw] text-[3.4vw] font-medium my-[1vw]">
                {language["ourvideo"]}
              </h1>
              <iframe
                className="w-full md:h-[16vw] h-[40vw] md:rounded-[1vw] rounded-[2vw]"
                src="https://www.youtube.com/embed/0Keoo5Sd3pM"
                title=""
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <h1 className="text-[#fff] md:text-left text-center  md:text-[1.4vw] text-[3.4vw] font-medium my-[1vw]">
                {language["social"]}
              </h1>
              <div className="flex md:justify-between justify-center md:gap-0 gap-[4vw]">
                <div className="rounded-[10vw] border border-transparent hover:border-[#fff] cursor-pointer p-[1vw] bg-[#ffffff44]">
                  <FacebookSvg />
                </div>
                <div className="rounded-[10vw] p-[1vw] border border-transparent hover:border-[#fff] cursor-pointer bg-[#ffffff44]">
                  <InstagramSvg />
                </div>
                <div className="rounded-[10vw] p-[1vw] border border-transparent hover:border-[#fff] cursor-pointer bg-[#ffffff44]">
                  <YoutTubeSvg />
                </div>
                <div className="rounded-[10vw] p-[1vw] border border-transparent hover:border-[#fff] cursor-pointer bg-[#ffffff44]">
                  <TiktokSvg />
                </div>
              </div>
              <button className="p-[1vw] md:rounded-[.4vw] rounded-[1.8vw] bg-[#ffffff44] w-full text-[#fff] md:text-[1.2vw] text-[3.2vw] md:mt-[1vw] mt-[8vw]" onClick={()=>setModal(true)}>
                {language["header4"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
