import React, { useState, useEffect } from "react";
import { NotificationSvg } from "./Svgs";

function Notification({ text, bottom }) {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 4000);
  }, []);

  return bottom ? (
    <div
      className="absolute whitespace-nowrap right-0 flex items-center gap-[1vw] bg-[#acc2da] p-[1vw] text-[1vw] rounded-[.6vw] notify z-[2] bottom-[-3vw] transition-[.7s]"
      style={{
        opacity: visible ? 1 : 0
      }}
    >
      <NotificationSvg />
      {text}
    </div>
  ) : (
    <div
      className="absolute whitespace-nowrap right-0 flex items-center gap-[1vw] bg-[#acc2da] p-[1vw] text-[1vw] rounded-[.6vw] notify z-[2] top-[-6vw] top transition-[.7s]"
      style={{
        opacity: visible ? 1 : 0
      }}
    >
      <NotificationSvg />
      {text}
    </div>
  );
}

export default Notification;
