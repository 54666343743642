import React, { useState, useEffect } from "react";
import {
  ArrowSvg,
  BagSvg,
  CategoryTruck,
  CloseSvg,
  Engeneer,
  FacebookSvg,
  GigantMachine,
  InstagramSvg,
  PriceSvg,
  Protest,
  Speedometer,
  TajribaSvg,
  TiktokSvg,
  ToBottomArrow,
  ToroziSvg,
  TruckerSvg,
  WarrantySvg,
  Weight,
  YoutTubeSvg,
  YukMashina
} from "../components/Svgs";
import Slider from "react-slick";
import DraggbleSlider from "../components/DraggbleSlider";
import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";
import Accordion from "../components/Accordion";
import Scrollspy from "react-scrollspy";
import { Men, SliceBattery } from "../../grandcharge/components/Svgs";
import { useGlobal } from "../../redux/selectors";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Contact from "../components/Contact";
import { getRestApi } from "../requests";
import axios from "axios";
import Modal from "../components/Modal";
function Home({ active }) {
  const [topSlider, setTopSlider] = useState({ id: "" });
  const [products, setProducts] = useState([]);
  const [banner, setBanner] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [gallery, setGallery] = useState([]);
  // const [count, setCount] = useState(1);
  let count = 1;
  const [modal, setModal] = useState(false);
  const [activebanner, setActiveBanner] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    afterChange: (e) => {
      setActiveBanner(e);
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const onSubmit = (data) => {
    toast(`So'rovingiz yuborildi ${data?.name}`);
    reset({
      name: "",
      phone: "",
      car_name: ""
    });

    let botMessege = `
    Assalomu aleykum😊%0A
      Ism👤 :  ${data?.name}%0A
      Telefon raqam📲 : ${data?.phone}%0A
      Truck : ${data?.car_name}%0A
      GrandTruck %0A
    `;

    axios({
      method: "get",
      url: `https://api.telegram.org/bot5950573944:AAFqMgsqESPhNP7KtC3FwTLlas0DSI75xuk/sendMessage?chat_id=-1001543956130&text=${botMessege}&parse_mode=HTML`
    });
  };

  useEffect(() => {
    getRestApi("product/", setProducts);
    getRestApi("slider/", setBanner);
    getRestApi("category/", setCategorys);
    getRestApi("FAQ/", setFaqs);
    getRestApi("blog/", setBlogs);
    getRestApi("Gallery/", setGallery);
  }, []);
  useEffect(() => {
    setTopSlider({ id: 0, product: products[0] });
  }, [products]);

  const { language, currentLang } = useGlobal();
  useEffect(() => {
    document.querySelector(".animate-slider").style.display = "none";
    setTimeout(() => {
      document.querySelector(".animate-slider").style.display = "block";
    }, 40);
  }, [topSlider]);

  return (
    <div className="bg-truck">
      {modal && (
        <Modal
          truck={topSlider?.product?.[`name_${currentLang}`]}
          close={() => setModal(false)}
        />
      )}
      <ToastContainer />
      <div>
        <Scrollspy
          className="fixed flex flex-col left-[4vw] bottom-[4vw] z-[90] md:text-[1.2vw] text-[3.2vw]"
          items={["one", "two", "three"]}
          currentClassName="active__nav"
        >
          <li className="opacity-40 hover:opacity-75 relative">
            <a href="#one" className="text-[#fff]">
              {language["bar1"]}
            </a>
          </li>
          <li className="opacity-40 hover:opacity-75 relative">
            <a href="#two" className="text-[#fff]">
              {language["bar2"]}
            </a>
          </li>
          <li className="opacity-40 hover:opacity-75 relative">
            <a href="#three" className="text-[#fff]">
              {language["bar3"]}
            </a>
          </li>
        </Scrollspy>
      </div>
      <div className="relative">
        <div className="absolute bottom-[4vw] flex w-[80%] justify-between left-[10%] z-[40]">
          <div className="backdrop-blur flex md:relative absolute px-[3vw]  bg-[#00000031] md:p-[1vw] p-[3vw] md:rounded-[1vw] rounded-[2vw] md:w-[40vw] w-[100%] md:bottom-0 bottom-[75vh] justify-between">
            <div className="flex items-center gap-[1vw]">
              <TruckerSvg />{" "}
              <p className="text-[#fff] md:text-[1.3vw] text-[3.3vw]">
                {banner?.[activebanner]?.[`name_${currentLang}`]}
              </p>
            </div>

            <div className="flex items-center gap-[1vw]">
              <BagSvg />{" "}
              <p className="text-[#fff] md:text-[1.3vw] text-[3.3vw]">
                {banner?.[activebanner]?.[`description_${currentLang}`]}
              </p>
            </div>
          </div>
          <Link
            to={"/product/" + banner?.[activebanner]?.link}
            className="text-[#fff] md:bottom-0 bottom-[10vh] md:text-[1.8vw] text-[3.8vw] relative flex items-center md:gap-[2vw] gap-[4vw] afters after:border-[#FECE00]"
          >
            {language["explore"]} <ArrowSvg />
          </Link>
        </div>
        <Slider {...settings}>
          {banner?.map((item, i) => (
            <div key={i} className="h-[100vh] relative">
              {/* <img
                src={
                  item?.image ??
                  "https://www.volvotrucks.com/content/dam/volvo-trucks/markets/master/home/transport-needs/volvo-trucks-transport-needs-regional-haul-hero-link-img.jpg"
                }
                className="w-full h-[100vh] object-cover"
                alt=""
              /> */}
              <video
                className="w-full h-[100vh] object-cover"
                muted
                autoPlay
                loop
                src={
                  item?.video ??
                  "https://www.volvotrucks.com/content/dam/volvo-trucks/markets/master/home/transport-needs/volvo-trucks-transport-needs-regional-haul-hero-link-img.jpg"
                }
              ></video>
            </div>
          ))}
        </Slider>
      </div>
      <div className="pt-[6vw]" id="one">
        <div className="bg-[#ffffff1a] md:rounded-[1vw] rounded-[3vw] md:w-[70%] w-[80%] m-auto p-[2vw]">
          <h1
            id="category"
            className="md:text-[2vw] text-[4vw] text-[#fff] text-center capitalize craftsemibold mb-[2vw]"
          >
            {language["h1"]}
          </h1>
          {/* <div className="flex md:flex-col flex-col-reverse gap-[2vw]">
            <div className="flex gap-[2vw] justify-between">
              <Link
                to="/category"
                className="bg-[#9c989563] md:w-full w-[48%] md:hover:w-[150%] transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
              >
                <img
                  src="https://www.volvotrucks.com/content/dam/volvo-trucks/markets/master/home/transport-needs/volvo-trucks-transport-needs-regional-haul-hero-link-img.jpg"
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <GigantMachine />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  Qazidigan mashina
                </h1>
              </Link>
              <Link
                to="/category"
                className="bg-[#9c989563] md:w-full w-[48%] md:hover:w-[150%] transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
              >
                <img
                  src="https://www.volvotrucks.com/content/dam/volvo-trucks/markets/master/home/transport-needs/volvo-trucks-transport-needs-regional-haul-hero-link-img.jpg"
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <YukMashina />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  Tejamkor mashina
                </h1>
              </Link>
            </div>
            <div className="flex gap-[2vw] md:flex-nowrap flex-wrap justify-between">
              <Link
                to="/category"
                className="bg-[#9c989563] w-full md:hover:w-[150%] transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
              >
                <img
                  src="https://www.volvotrucks.com/content/dam/volvo-trucks/markets/master/home/transport-needs/volvo-trucks-transport-needs-regional-haul-hero-link-img.jpg"
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <GigantMachine />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  Oilaviy mashina
                </h1>
              </Link>
              <Link
                to="/category"
                className="bg-[#9c989563] md:w-full w-[48%] md:hover:w-[150%] transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
              >
                <img
                  src="https://www.volvotrucks.com/content/dam/volvo-trucks/markets/master/home/transport-needs/volvo-trucks-transport-needs-regional-haul-hero-link-img.jpg"
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <CategoryTruck />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  Qazidigan mashina
                </h1>
              </Link>
              <Link
                to="/category"
                className="bg-[#9c989563] md:w-full w-[48%] md:hover:w-[150%] transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
              >
                <img
                  src="https://www.volvotrucks.com/content/dam/volvo-trucks/markets/master/home/transport-needs/volvo-trucks-transport-needs-regional-haul-hero-link-img.jpg"
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <YukMashina />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  Yuk mashina
                </h1>
              </Link>
            </div>
          </div> */}
          <div className="grid md:grid-cols-3 gap-[2vw] grid-cols-2">
            {categorys?.map((item, i) => (
              <Link
                to="/products"
                className="bg-[#9c989563] md:w-full transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
                key={i}
                onClick={() => {
                  localStorage.setItem("category", item?.id);
                }}
              >
                <img
                  src={item?.image}
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <img
                  src={item?.icon}
                  className="w-full md:h-[8vw] h-[20vw] object-contain"
                  alt=""
                />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  {item?.[`name_${currentLang}`]}
                </h1>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className="pt-[6vw]">
        <div className="bg-[#ffffff1a] md:rounded-[1vw] rounded-[2vw] md:w-[70%] w-[90%] m-auto md:p-[2vw] p-[4vw]">
          <div className="bg-[#ffffff1a] rounded-[1vw] m-auto p-[2vw]">
            <h1 className="md:text-[2vw] text-[4vw] md:text-left text-center text-[#fff] capitalize craftsemibold mb-[2vw]">
              {language["aboutusname"]}
            </h1>
            <div className="flex md:flex-row flex-col gap-[3vw]">
              <div className="flex flex-col gap-[2vw]">
                <iframe
                  className="md:w-[26vw] w-full md:h-[16vw] h-[40vw] rounded-[1vw]"
                  src="https://www.youtube.com/embed/0Keoo5Sd3pM"
                  title=""
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div className="flex md:justify-start justify-center gap-[2vw]">
                  <div className="bg-[#ffffff1a] md:p-[.8vw] p-[2vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                    <FacebookSvg />
                  </div>
                  <div className="bg-[#ffffff1a] md:p-[.8vw] p-[2vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                    <YoutTubeSvg />
                  </div>
                  <div className="bg-[#ffffff1a] md:p-[.8vw] p-[2vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                    <InstagramSvg />
                  </div>
                  <div className="bg-[#ffffff1a] md:p-[.8vw] p-[2vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                    <TiktokSvg />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[2vw]">
                <p className="text-[#fff] md:text-[1.4vw] text-[3.4vw] text-justify craftregular">
                  {language["aboutustextmini"]}
                </p>
                <div className="flex md:justify-start justify-center gap-[2vw]">
                  <Link
                    to="/about"
                    className="md:p-[.5vw] md:px-[1.2vw] p-[1.5vw] px-[3.2vw] text-[#fff] hover:bg-[#ffffff6e] bg-[#ffffff2d] md:rounded-[.4vw] rounded-[2vw] md:text-[1.4vw] text-[3.5vw]"
                  >
                    {language["page1"]}
                  </Link>
                  <button
                    onClick={() => active(1)}
                    className="md:p-[.5vw] md:px-[1.2vw] p-[1.5vw] px-[3.2vw] text-[#000] hover:bg-[#fbff00] bg-[#5AFFB3] md:rounded-[.4vw] rounded-[2vw] md:text-[1.4vw] text-[3.5vw]"
                  >
                    {language["aboutusbtn2"]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-[6vw]" id="two">
        <div className="bg-[#ffffff1a] rounded-[1vw] md:w-[70%] w-[80%] m-auto md:p-[2vw] p-[4vw]">
          <h1 className="md:text-[2vw] text-[4vw] text-[#fff] text-center capitalize craftsemibold mb-[2vw]">
            {language["recomendation"]}
          </h1>
          <div className="bg-[#ffffff1a] md:h-[30vw] h-[70vw] relative md:rounded-[1vw] rounded-[2vw] w-full m-auto md:p-[2vw] pr-0">
            <img
              src={require("../../assets/img/bgslide.png")}
              className="absolute top-0 left-0 h-[100%] z-10 md:block hidden"
            />
            <div className="relative flex items-center md:flex-row flex-col overflow-hidden">
              <div className="md:w-[55%] w-full z-20  md:flex flex-col justify-between md:h-[27vw] h-[45vw] md:bg-[#ff0b0b00] bg-[#FECE00] rounded-[2vw]">
                <div className="flex justify-between md:p-0 p-[2vw]">
                  <h1 className="md:text-[2vw] text-[4vw] craftsemibold text-[#002C4E]  whitespace-nowrap text-ellipsis overflow-hidden">
                    {topSlider?.product?.[`name_${currentLang}`]}
                  </h1>
                </div>
                <img
                  className="md:h-[15vw] h-[30vw] md:object-cover md:w-[15vw] w-[30vw] animate-slider md:relative absolute md:right-[-7vw] right-[2vw] rounded-[1vw] md:border-[#002c4e86] md:border-[.3vw]"
                  src={topSlider?.product?.image1}
                  alt=""
                />
                <div className="flex gap-[2vw] md:flex-row flex-col md:p-0 p-[2vw]">
                  <div className="flex  md:gap-[.4vw] gap-[1.5vw] items-center text-[#002C4E]">
                    <Weight />
                    <div className="flex flex-col">
                      <h1 className="md:text-[1.1vw] text-[3.1vw] font-medium md:leading-[1.1vw] leading-[3.1vw]">
                        {topSlider?.product?.weight}
                      </h1>
                      <p className="text-[1.7vw] md:w-[4vw] leading-[2.7vw] md:text-[.7vw] md:leading-[.7vw]">
                        {language["icon1"]}
                      </p>
                    </div>
                  </div>
                  <div className="flex  md:gap-[.4vw] gap-[1.5vw] items-center text-[#002C4E]">
                    <Engeneer />
                    <div className="flex flex-col">
                      <h1 className="md:text-[1.1vw] text-[3.1vw] font-medium md:leading-[1.1vw] leading-[3.1vw]">
                        {topSlider?.product?.bear_ability}
                      </h1>
                      <p className="text-[1.7vw]  md:w-[4vw] leading-[2.7vw] md:text-[.7vw] md:leading-[.7vw]">
                        {language["icon2"]}
                      </p>
                    </div>
                  </div>
                  <div className="flex  md:gap-[.4vw] gap-[1.5vw] items-center text-[#002C4E]">
                    <Protest />
                    <div className="flex flex-col">
                      <h1 className="md:text-[1.1vw] text-[3.1vw] font-medium md:leading-[1.1vw] leading-[3.1vw]">
                        {topSlider?.product?.power} WaT
                      </h1>
                      <p className="text-[1.7vw]  md:w-[4vw] leading-[2.7vw] md:text-[.7vw] md:leading-[.7vw]">
                        {language["icon3"]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-[44%] w-full">
                <div className="relative md:w-[120%] w-[100%] md:right-[20%]">
                  <ScrollContainer
                    className="scrollContainer"
                    horizontal={true}
                  >
                    <div className="flex py-[2vw]">
                      {products?.map((item, i) => (
                        <div
                          id={"btn-" + i}
                          onClick={() => {
                            setTopSlider({ id: i, product: item });
                            document
                              .querySelector(".scrollContainer")
                              .scrollTo(
                                document.getElementById(`btn-${i}`).offsetLeft +
                                  100,
                                0
                              );
                          }}
                        >
                          <div
                            className={`transition-[.4s] md:h-[10vw] md:w-[10vw] w-[30vw] py-[1vw] opacity-60 hover:opacity-100 cursor-pointer`}
                            style={{
                              transform:
                                topSlider.id === item
                                  ? "scale(1.1)"
                                  : "scale(.9)"
                            }}
                          >
                            <img
                              src={item?.image1}
                              className="md:w-[10vw] w-[25vw] md:h-[10vw] h-[25vw] rounded-[1vw]  object-cover md:border-[#FECE00] md:border-[.3vw]"
                              alt=""
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </ScrollContainer>
                </div>
                <div className="flex gap-[5vw] w-full absolute md:right-[2vw] right-[-2vw] md:justify-end md:bottom-[1vw] bottom-[38%] z-[60] text-[1.2vw] md:text-[#fff] text-[#fff]">
                  {/* <div className="md:flex hidden items-end gap-[1vw]">
                    <p className="font-thin">{language["cost_car"]}</p>
                    <h1 className="text-[1.4vw] font-medium">
                      {topSlider?.product?.price} $
                    </h1>
                  </div> */}
                  <button
                    className="md:text-[1.2vw] text-[3vw] rounded-[1vw] py-[.4vw] px-[2vw] btn-rotate md:bg-transparent bg-[#002C4E]"
                    onClick={() => setModal(true)}
                  >
                    {language["buy"]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="lizing"
        className="h-screen md:block flex justify-center items-center relative mt-[6vw]"
      >
        <img
          src={require("../../assets/img/lizing.png")}
          className="w-full absolute top-0 left-0 h-screen object-cover  object-[0%]"
          alt=""
        />
        <div className="relative md:w-auto w-[90%] md:bg-transparent bg-[#00000009]   md:backdrop-blur-0 backdrop-blur-lg">
          <h1 className="craftsemibold pt-[4vw] text-center text-[#fff] craftsemibold md:text-[2vw] text-[3.8vw]">
            {language["lizing"]}
          </h1>
          <div className="md:bg-[#ffffff0c] md:backdrop-blur-md md:ml-[50%] ml-0 md:w-[40vw] w-[100%] md:mt-[4vw] md:p-[2vw] p-[4vw] md:rounded-[1vw] rounded-[2vw]">
            <div className="md:bg-[#ffffff0a] md:p-[2vw]  md:rounded-[1vw] rounded-[2vw]">
              <p className="text-[#fff] md:text-[1.4vw] text-[3vw] craftregular">
                {language["desc_lizing"]}
              </p>
              <div className="flex gap-[2vw] mt-[2vw] md:px-[3vw]">
                <button
                  className="p-[.5vw] w-full px-[1.2vw] text-[#000] bg-[#FECE00] hover:bg-[#ddc86b] rounded-[2vw] md:text-[1.2vw] text-[3vw] md:rounded-[.4vw]"
                  onClick={() => setModal(true)}
                >
                  {language["lizingbtn1"]}
                </button>
                <button
                  onClick={() => {
                    window.scrollTo(0, 9999);
                  }}
                  className="md:p-[.5vw] p-[2vw] w-full px-[1.2vw] text-[#ffffff] bg-[#ffffff28] rounded-[2vw] md:text-[1.2vw] text-[3vw] md:rounded-[.4vw]"
                >
                  {language["lizingbtn2"]}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[6vw] bg-[#ffffff1a] md:w-[70%] w-[90%] m-auto md:p-[2vw] p-[4vw] md:rounded-[1vw] rounded-[2vw]">
        <h1 className="text-[#fff] md:text-[2vw] text-[4vw] md:text-left text-center mb-[2vw] craftsemibold">
          {language["delivery"]}
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex md:flex-row flex-col justify-between"
        >
          <div className=" bg-[#ffffff1a] md:p-[2vw] p-[4vw] md:w-[60%] md:rounded-[1vw] rounded-[2vw]">
            <div className="flex gap-[2vw] items-center">
              <img
                src={require("../../assets/img/logo.png")}
                className="w-[6vw] md:block hidden"
              />
              <h1 className="text-[#fff] md:text-[1.2vw] text-[3.2vw] craftsemibold">
                {language["choose"]}
              </h1>
            </div>
            <p className="md:text-[1vw] text-[2.2vw] text-[#fff] mt-[2vw] craftregular">
              {language["delivery_text"]}
            </p>
            {/* <select className="w-full mt-[2vw] md:p-[1.5vw] p-[2.5vw] md:text-[1.4vw] text-[3vw]   bg-[#ffffff21] outline-none text-[#fff] rounded-[.6vw] md:mb-[3vw] mb-[6vw]">
              <option value="Modelni kiriting">
                {language["delivery_choose"]}
              </option>
              <option value="Modelni kiriting">Modelni kiriting</option>
            </select> */}
            <input
              type="text"
              placeholder={language["delivery_choose"]}
              className="w-full focus:outline-[#FECE00]  mt-[2vw] md:p-[1vw] p-[2.5vw] md:text-[1.4vw] text-[3vw]   bg-[#ffffff21] outline-none text-[#fff] rounded-[.6vw] md:mb-[3vw] mb-[6vw]"
              {...register("car_name", { required: true })}
            />
            <div className="px-[4vw]">
              <img
                src={require("../../assets/img/camc.png")}
                className="w-full"
                alt=""
              />
            </div>
          </div>
          <div className="md:w-[38%]">
            <iframe
              className="w-full h-[16vw] rounded-[1vw] md:block hidden"
              src="https://www.youtube.com/embed/0Keoo5Sd3pM"
              title=""
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <div className=" bg-[#ffffff1a] md:p-[2vw] p-[4vw] w-full mt-[2vw] md:rounded-[1vw] rounded-[2vw]">
              <h1 className="text-[#fff] md:text-[1.6vw] text-[3.6vw] mb-[1.7vw]">
                {language["deliveryname"]}
              </h1>
              <input
                type="text"
                placeholder={language["name"]}
                className="w-full focus:outline-[#FECE00] md:p-[1vw] p-[2vw] md:mb-[1vw] mb-[2vw]  bg-[#ffffff21] outline-none text-[#fff] rounded-[.6vw] md:text-[1.4vw] text-[3vw] "
                {...register("name", { required: true })}
                style={{
                  borderColor: errors?.name && "red"
                }}
              />
              <input
                type="number"
                placeholder={language["phone"]}
                className="w-full focus:outline-[#FECE00] md:p-[1vw] p-[2vw] md:mb-[1vw] mb-[2vw]  bg-[#ffffff21] outline-none text-[#fff] rounded-[.6vw] md:text-[1.4vw] text-[3vw] "
                {...register("phone", { required: true, minLength: 9 })}
                style={{
                  borderColor: errors?.phone && "red"
                }}
              />
              <p className="md:text-[.8vw] text-[2.8vw] text-[#fff] mb-[2vw] craftregular ">
                {language["delivery_text_p"]}
              </p>
              <div className="flex gap-[2vw] mt-[2vw]">
                <button
                  className="md:p-[.5vw] p-[1.5vw] w-full px-[1.2vw] text-[#000] bg-[#FECE00] hover:bg-[#ddc86b] rounded-[.4vw] md:text-[1.4vw] text-[3vw]"
                  type="submit"
                >
                  {language["send"]}
                </button>
                <button
                  className="md:p-[.5vw] p-[1.5vw] w-full px-[1.2vw] text-[#000] bg-[#fff] rounded-[.4vw] md:text-[1.4vw] text-[3vw] "
                  type="button"
                  onClick={() => {
                    window.scrollTo(0, 9999);
                  }}
                >
                  {language["location"]}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="mt-[6vw] bg-[#ffffff21] md:w-[70%] w-[90%] m-auto md:p-[2vw] p-[4vw]  md:rounded-[1vw] rounded-[2vw]">
        <div className="flex gap-[2vw] items-center">
          <img
            src={require("../../assets/img/logo.png")}
            className="w-[8vw] md:block hidden"
          />
          <h1 className="craftsemibold text-[#fff] md:text-[2vw] text-[4vw]">
            {language["photogallery"]}
          </h1>
        </div>
        <div class="flex flex-wrap justify-between md:gap-[1vw] gap-[3vw] grid-cols-5 w-full mt-[2vw] ">
          {/* <div class="grid md:gap-[1vw] gap-[3vw] grid-cols-5 w-full mt-[2vw]"> */}
          {gallery?.map((item, i) => {
            if (count < 4) {
              count++;
            } else {
              count = 1;
            }
            return (
              <div key={i} className={"liv" + count}>
                <label htmlFor={i}>
                  <input
                    type="checkbox"
                    className="open-image hidden"
                    name={i}
                    id={i}
                  />
                  <img
                    src={item?.image}
                    alt=""
                    className={`w-[100%] h-[20vw] object-cover rounded-[1vw] transition-[.4s] cursor-search`}
                  />
                </label>
              </div>
            );
          })}
        </div>
        <div className="flex flex-wrap gap-[1vw] justify-between mt-[2vw]"></div>
      </div>
      <div
        className="mt-[6vw] bg-[#ffffff21] md:w-[85%] md:ml-[15%] ml-[5%] w-[95%] md:p-[2vw] p-[4vw] pr-0 md:rounded-l-[1vw] rounded-l-[2vw]"
        id="news"
      >
        <div className="flex justify-between md:w-[80%] w-[95%]">
          <h1 className="craftsemibold text-[#fff] md:text-[2vw] text-[4vw]">
            {language["news"]}
          </h1>
          <button className="py-[.5vw] px-[2vw] bg-[#ffffff28] text-[#fff] md:text-[1.2vw] text-[2.2vw] rounded-[.3vw]">
            28.09.2022
          </button>
        </div>
        <DraggbleSlider data={blogs} lang={currentLang} />
      </div>

      <div className="mt-[6vw] bg-[#ffffff21] md:w-[70%] w-[90%] m-auto md:p-[2vw] p-[4vw] md:rounded-[1vw] rounded-[2vw]">
        <h1 className="craftsemibold text-[#fff] md:text-[2vw] text-[4vw] mb-[2vw]">
          {language["faq"]}
        </h1>
        <div className="p-[4vw] justify-between bg-[#ffffff] rounded-[1vw] flex flex-col gap-[2vw]">
          {faqs?.map((item, i) => (
            <Accordion data={item} lang={currentLang} key={i} />
          ))}
        </div>
      </div>

      <div className="mt-[6vw] bg-[#ffffff21] md:w-[70%] w-[90%] m-auto md:p-[2vw] p-[4vw]  py-[4vw] md:rounded-[1vw]  rounded-[2vw] flex items-center justify-between">
        <div className="flex flex-col items-center gap-[2vw]">
         <div className="hover-icon">
           <ToroziSvg />
         </div>
          <p className="md:text-[1.4vw] text-[2.4vw] text-[#fff]">
            {language["utp1"]}
          </p>
        </div>
        <div className="flex flex-col items-center gap-[2vw]">
         <div className="hover-icon">
           <TajribaSvg />
         </div>
          <p className="md:text-[1.4vw] text-[2.4vw] text-[#fff]">
            {language["utp2"]}
          </p>
        </div>
        <div className="flex flex-col items-center gap-[2vw]">
         <div className="hover-icon">
           <PriceSvg />
         </div>
          <p className="md:text-[1.4vw] text-[2.4vw] text-[#fff]">
            {language["utp3"]}
          </p>
        </div>
        <div className="flex flex-col items-center gap-[2vw]">
         <div className="hover-icon">
           <WarrantySvg />
         </div>
          <p className="md:text-[1.4vw] text-[2.4vw] text-[#fff]">
            {language["utp4"]}
          </p>
        </div>
      </div>

      <Contact />
    </div>
  );
}

export default Home;
