import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { MobilePhone } from "../../grandcharge/components/Svgs";
import { setLanguage } from "../../redux/actions/globalActions";
import { useGlobal } from "../../redux/selectors";
import { ContactMan } from "./Svgs";

function Navbar() {
  const { language, currentLang } = useGlobal();
  const dispatch = useDispatch();
  const changeLanguage = (e) => {
    dispatch(setLanguage(e.target.value));
  };
  const param = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [param]);

  const [navbar, setNavbar] = useState(false);
  return (
    <div>
      <nav className="px-[4vw] flex  justify-between py-[2vw] backdrop-blur md:bg-[#00000060] bg-[#00000060] fixed w-full top-0 right-0 z-50">
        <div className="flex md:hidden justify-between w-full text-[#fff] text-[4vw] items-center">
          <button
            className="w-[10vw] h-[10vw] flex items-center justify-center bg-[#00000060] rounded-[30vw]"
            onClick={() => setNavbar(!navbar)}
          >
            ☰
          </button>
          <img
            className="w-[20vw]"
            src={require("../../assets/img/logo.png")}
            alt=""
          />
          <a href="tel:+998995535959">
            <button className="w-[10vw] h-[10vw] flex items-center justify-center bg-[#00000060] rounded-[30vw]">
              <MobilePhone />
            </button>
          </a>
        </div>
        <ul
          className="flex md:w-[75%] w-[90%] md:gap-0 gap-[4vw] md:p-0 p-[4vw] items-center justify-between md:static md:flex-row flex-col absolute md:bg-transparent bg-[#00000060] md:top-0 top-[-100vh] transition-[.4s]"
          id={navbar && "active"}
        >
          <li className=" md:block hidden">
            <Link
              to="/"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              <img
                className="w-[10vw]"
                src={require("../../assets/img/logo.png")}
                alt=""
              />
            </Link>
          </li>
          <li>
            <a
              href="/#category"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              {language["header1"]}
            </a>
          </li>
          <li>
            <a
              href="/#lizing"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              {language["header2"]}
            </a>
          </li>
          <li>
            <a
              href="/#news"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              {language["header3"]}
            </a>
          </li>

          <li>
            <a
              href="/#contact"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              {language["header4"]}
            </a>
          </li>
          <select
            name=""
            id=""
            defaultValue={localStorage["lang"] || "uz"}
            onClick={changeLanguage}
            className="text-[#fff] text-[3vw] bg-transparent outline-none md:hidden flex"
          >
            <option value="uz">Uz</option>
            <option value="ru">Ru</option>
            <option value="en">En</option>
          </select>
        </ul>
        <ul className="md:flex hidden items-center gap-[2vw] justify-between">
          <li>
            <button className="flex items-center gap-[.4vw] bg-[#ffffff46] p-[.4vw] rounded-[.3vw]">
              <img
                src={`assets/img/${currentLang}.png`}
                className="w-[1.8vw] h-[1.3vw] object-cover"
                alt=""
              />
              <select
                name=""
                id=""
                defaultValue={localStorage["lang"] || "uz"}
                onClick={changeLanguage}
                className="text-[#fff] text-[1vw] bg-transparent outline-none"
              >
                <option value="uz">Uz</option>
                <option value="ru">Ru</option>
                <option value="en">En</option>
              </select>
            </button>
          </li>
          <li>
            <a href="tel:+998995535959">
              <button className="flex items-center gap-[.4vw] bg-[#ffffff46] p-[.4vw] px-[1vw] rounded-[.3vw] text-[1vw] text-[#fff]">
                <ContactMan />
                {language["header_call"]}
              </button>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
