import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { ScrollBottom } from "../../grandcharge/components/Svgs";
import { useGlobal } from "../../redux/selectors";
import Contact from "../components/Contact";
import {
  ArrowLeft,
  ArrowRight,
  CallSvg,
  Emkost,
  FacebookSvg,
  SpeedometerBue,
  WhiteSpeedmeter
} from "../components/Svgs";
import { getRestApi } from "../requests";
function Category() {
  function SampleNextArrow({ onClick }) {
    return (
      <div
        onClick={onClick}
        className="absolute md:bottom-[40%] bottom-[20%] right-[12vw] bg-[#ffffff3a] hover:bg-[#FECE00] w-[8vw] h-[8vw] md:w-[4vw] md:h-[4vw] flex justify-center items-center rounded-[5vw] z-[30] cursor-pointer"
      >
        <ArrowRight />
      </div>
    );
  }
  function SamplePrevArrow({ onClick }) {
    return (
      <div
        onClick={onClick}
        className="absolute md:bottom-[40%] bottom-[20%] left-[12vw] bg-[#ffffff3a] hover:bg-[#FECE00] w-[8vw] h-[8vw] md:w-[4vw] md:h-[4vw] flex justify-center items-center rounded-[5vw] z-[30]  cursor-pointer"
      >
        <ArrowLeft />
      </div>
    );
  }
  const settingsBottomSlider = {
    dots: false,
    infinite: true,
    speed: 1300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (e) => {
      setActive(e);
    }
  };
  const [products, setProducts] = useState([]);
  const [active, setActive] = useState(0);
  useEffect(() => {
    getRestApi("product/", setProducts);
  }, []);
  const { language, currentLang } = useGlobal();
  return (
    <div className="bg-truck pt-[8vw]">
      <div className="bgofslidertruck relative md:h-[40vw] h-[90vw]  flex flex-col justify-between">
        <div>
          <div className="flex items-center justify-between w-[80%] m-auto">
            <div className="flex">
              <div className="flex flex-col mb-[2vw] mt-[1vw]">
                <h1 className="md:text-[2.2vw] text-[4.2vw] font-medium text-[#fff] capitalize craftsemibold">
                  бюджетные машины
                </h1>
                <p className="md:text-[1.1vw] text-[3.1vw] font-thin text-[#ffffff8f]">
                  Home / Категория / Tesla
                </p>
              </div>
            </div>
          </div>
          <Slider {...settingsBottomSlider}>
            {products?.map((item) => (
              <div key={item} className=" relative">
                <div className="md:p-[2vw] md:pt-[2vw] pt-[10vw]">
                  <img
                    src={item?.image1}
                    className="w-full md:h-[20vw] h-[40vw] object-contain"
                    alt=""
                  />
                </div>
              </div>
            ))}
          </Slider>
          <div className="flex md:flex-nowrap flex-wrap justify-center bg-[#5e5650] p-[2vw] md:py-[2vw] gap-[5vw] py-[8vw]">
            <p className="hidden md:flex gap-[2vw] items-center text-[#fff] text-[1vw]">
              <ScrollBottom />
              Scroll down for more
            </p>
            <h1 className="md:text-[2vw] text-[4vw] md:w-auto md:text-left text-center w-[100%] font-medium text-[#fff] capitalize">
              {products?.[active]?.[`name_${currentLang}`]}
            </h1>
            <button
              onClick={() => {
                window.scrollTo(0, window.innerHeight);
              }}
              className="text-[#fff] md:w-auto  w-[40%] bg-[#ffffff2a] px-[3vw] py-[1vw] md:rounded-[.5vw] rounded-[1vw] md:text-[1vw] text-[3vw]"
            >
              Xarakteristkalari
            </button>
            <button className="text-[#fff] md:w-auto  w-[40%] bg-[#FECE00] px-[3vw] py-[1vw] md:rounded-[.5vw] rounded-[1vw] md:text-[1vw] text-[3vw]">
              Xarid qilish
            </button>
          </div>
        </div>
      </div>
      <div className="p-[7vw] pt-[14vw] ">
        <div className="md:rounded-[1vw] rounded-[2vw] md:p-[1vw] p-[2vw]  bg-[#ffffff10]">
          <h1 className="capitalize md:text-left text-center craftsemibold md:text-[2vw] text-[4vw] text-[#fff] md:p-[1vw] p-[2vw] md:px-[2vw]">
            Об автомобиле
          </h1>
          <div className="grid md:grid-cols-[60%_38%] gap-[2vw]">
            <div className="bg-[#ffffff10] p-[2vw] rounded-[1vw] px-[4vw]">
              <h1 className="text-[#fff] md:text-[1.6vw] text-[3.6vw] font-medium my-[1vw]">
                {products?.[active]?.[`name_${currentLang}`]}
              </h1>
              <div className="flex items-center justify-between mb-[2vw]">
                <div className="md:w-auto w-full">
                  <div className="flex gap-[1vw] my-[1vw] w-full">
                    <Emkost />
                    <div className="flex flex-col md:w-[20vw] w-full">
                      <div className="flex justify-between">
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff] font-thin">
                          Емкость
                        </p>
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff]">
                          93,4 кВт·ч
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff] font-thin">
                          Запас хода (WLTP)
                        </p>
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff]">
                          370 км
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-[1vw] my-[1vw] w-full">
                    <SpeedometerBue />
                    <div className="flex flex-col w-full">
                      <div className="flex justify-between">
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff] font-thin">
                          Макс. скорость
                        </p>
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff]">
                          260 км/ч
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff] font-thin">
                          Разгон до 100 км/ч
                        </p>
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff]">
                          {" "}
                          3,2 с
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={require("../../assets/img/speed.gif")}
                  className="w-[15vw] md:block hidden rounded-[20vw]"
                  alt=""
                />
              </div>
              <img
                className="w-full"
                src={require("../../assets/img/360frame.png")}
                alt=""
              />
            </div>
            <div className="bg-[#ffffff10] p-[2vw] rounded-[1vw] md:sticky top-0">
              <h1 className="text-[#fff] md:text-[1.6vw] text-[3.6vw]  font-medium my-[1vw]">
                Mashinaga qilinga video obzor
              </h1>
              <iframe
                className="w-full md:h-[16vw] h-[40vw] md:rounded-[1vw] rounded-[2vw]"
                src="https://www.youtube.com/embed/0Keoo5Sd3pM"
                title=""
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <h1 className="text-[#fff] md:text-[1.1vw] text-[3.1vw] font-thin mt-[1vw]">
                Avtomobil nomi
              </h1>
              <h1 className="text-[#fff] md:text-[1.8vw] text-[3.8vw] font-medium mb-[2vw]">
                Byd model w
              </h1>
              <h1 className="text-[#fff] md:text-[1.1vw] text-[3.1vw] font-thin mt-[1vw]">
                Avtomobil narxi
              </h1>
              <h1 className="text-[#fff] md:text-[1.8vw] text-[3.8vw] font-medium mb-[2vw]">
                46.000$
              </h1>
              <div className="flex gap-[1vw] px-[2vw]">
                <button className="p-[1vw] rounded-[.6vw] bg-[#ffffff] w-full text-[#000] md:text-[1.2vw] text-[3.2vw] mt-[1vw]">
                  Xarid qilish
                </button>
                <button className="p-[1vw] rounded-[.6vw] bg-[#FECE00] text-[#000] text-[1.2vw] mt-[1vw]">
                  <CallSvg />
                </button>
              </div>
            </div>
            <div className="bg-[#ffffff10] p-[2vw] rounded-[1vw] px-[4vw]">
              <h1 className="text-[#fff] md:text-[1.6vw] text-[3.6vw] font-medium my-[1vw]">
                Description
              </h1>
              <p className="md:text-[1vw] text-[3vw] text-[#fff] text-justify">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Cupiditate repellendus culpa amet quos ea quas. Natus fugit
                tenetur repellat non. Ipsa voluptas eos necessitatibus! Tenetur
                obcaecati saepe maxime voluptatum repellat consequuntur fugit
                cupiditate corporis laborum consectetur laboriosam harum nihil
                ad distinctio quas aspernatur ut voluptas deleniti doloribus,
                eum ipsum explicabo. Sed nostrum quo iure, doloribus minima
                debitis ipsam reprehenderit commodi corporis similique
                <br />
                <br />
                accusantium laudantium, repellendus autem! Expedita, molestias
                architecto. Vero nisi explicabo at numquam consequatur
                consectetur, nesciunt non porro culpa. Nulla eveniet odio
                consequatur dolorum provident tenetur quos temporibus quas,
                recusandae, est porro laudantium. Ex porro fuga numquam, libero
                ullam accusamus veritatis exercitationem nostrum rem dolorum
                praesentium deserunt hic, consectetur, odio odit? Quos debitis
                ad porro, repellendus nisi vitae ipsa eligendi aliquam soluta
                <br />
                <br />
                non maxime! Architecto dignissimos commodi voluptatibus, optio
                sed, enim cumque aperiam necessitatibus animi omnis obcaecati
                cum fugit dolorem velit libero mollitia laboriosam ipsam odio
                dicta itaque quo vel vitae!
              </p>
            </div>
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
}

export default Category;
