import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setLanguage } from "../../redux/actions/globalActions";
import { useGlobal } from "../../redux/selectors";
import Notification from "./Notification";
import { ContactMan, MobilePhone } from "./Svgs";

function Navbar() {
  const { language, currentLang } = useGlobal();
  const dispatch = useDispatch();
  const changeLanguage = (e) => {
    dispatch(setLanguage(e.target.value));
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const param = useLocation();
  useEffect(() => {
    scrollToTop();
  }, [param]);
  const [navbar, setNavbar] = useState(false);
  return (
    <div>
      <nav className="px-[4vw] flex  justify-between md:py-[2vw] py-[4vw] backdrop-blur md:bg-[#1c2f4abd] bg-[#1c2f4a60] fixed w-full top-0 right-0 z-50 gap-[4vw]">
        <div className="flex md:hidden justify-between w-full text-[#fff] text-[4vw] items-center">
          <button
            className="w-[10vw] h-[10vw] flex items-center justify-center bg-[#002c4e3b] rounded-[30vw]"
            onClick={() => setNavbar(!navbar)}
          >
            ☰
          </button>
          <img
            className="w-[30vw]"
            src={require("../../assets/img/logocharge.png")}
            alt=""
          />
          <a href="tel:+998995535959">
            <button className="w-[10vw] h-[10vw] flex items-center justify-center bg-[#002c4e3b] rounded-[30vw]">
              <MobilePhone />
            </button>
          </a>
        </div>
        <ul
          className="flex md:w-[75%] w-[90%] md:gap-[2vw] gap-[4vw] md:p-0 p-[4vw] items-center justify-between md:static md:flex-row flex-col absolute md:bg-transparent bg-[#1c2f4abd] md:top-0 top-[-100vh] transition-[.4s]"
          id={navbar && "active"}
        >
          <li className=" md:block hidden">
            <Link
              to="/"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              <img
                className="w-[10vw]"
                src={require("../../assets/img/logocharge.png")}
                alt=""
              />
            </Link>
          </li>
          <li>
            <Link
              to="/category"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              {language["h1"]}
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              {language["h2"]}
            </Link>
          </li>
          <li>
            <a
              href="/#calc"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              {language["h3"]}
            </a>
          </li>

          <li>
            <a
              href="/#charge"
              className="md:text-[1.2vw] text-[3vw] text-[#fff] hover:opacity-60"
            >
              {language["h4"]}
            </a>
          </li>
          <select
            name=""
            id=""
            defaultValue={localStorage["lang"] || "uz"}
            onClick={changeLanguage}
            className="text-[#fff] text-[3vw] bg-transparent outline-none md:hidden flex"
          >
            <option value="uz">Uz</option>
            <option value="ru">Ru</option>
            <option value="en">En</option>
          </select>
        </ul>
        <ul className="md:flex hidden items-center gap-[2vw] justify-between">
          <li>
            <button className="flex items-center gap-[.4vw] bg-[#ffffff46] p-[.4vw] rounded-[.3vw]">
              <img
                src={`assets/img/${currentLang}.png`}
                className="w-[1.8vw] h-[1.3vw] object-cover"
                alt=""
              />
              <select
                name=""
                id=""
                defaultValue={localStorage["lang"] || "uz"}
                onClick={changeLanguage}
                className="text-[#fff] text-[1vw] bg-transparent outline-none"
              >
                <option value="uz">Uz</option>
                <option value="ru">Ru</option>
                <option value="en">En</option>
              </select>
            </button>
          </li>
          <li>
            <a href="tel:+998995535959">
              <button className="flex items-center gap-[.4vw] bg-[#ffffff46] p-[.4vw] px-[1vw] rounded-[.3vw] text-[1vw] text-[#fff]">
                <ContactMan />
                {language["h5"]}
              </button>
            </a>
          </li>
        </ul>
      </nav>
      <div
        className="fixed z-[90] right-[6vw] bottom-[3vw] cursor-pointer"
        onClick={scrollToTop}
      >
        <div className="relative">
          <Notification text={"Tepaga chiqish uchun bosing"} />
          <img
            src={require("../../assets/img/gotop.png")}
            className="md:w-[8vw] w-[12vw] backdrop-blur-xl rounded-[50vw]"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
