import React, { useState, useEffect } from "react";
import {
  AndroidSvg,
  ArrowLeft,
  ArrowRight,
  ArrowSvg,
  BagSvg,
  BatSlider,
  Battery,
  CategoryTruck,
  ChargerBattery,
  FacebookSvg,
  FamilyCar,
  FastCar,
  ItunesSvg,
  KmWat,
  Location,
  Men,
  MenSlider,
  MessengerSvg,
  MobilePhone,
  MobileThree,
  MoneyBag,
  Petrolist,
  SliceBattery,
  Speedometer,
  TejamkorCar,
  WhiteSpeedmeter,
  InstagramSvg,
  TiktokSvg,
  YoutTubeSvg,
  YouTubeMarker
} from "../components/Svgs";
import Slider from "react-slick";
import Ranger from "../../grandtruck/components/Ranger";
import Notification from "../components/Notification";
import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import MapCharges from "../components/MapCharges";
import { useGlobal } from "../../redux/selectors";

import { getRestApi } from "../requests";
import Contact from "../components/Contact";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../components/Modal";

function Home({ active }) {
  const [topSlider, setTopSlider] = useState({ id: "" });
  const [chargeCar, setChargeCar] = useState(36000);
  const [petrolCar, setPetrolCar] = useState(1000000);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [videos, setVideos] = useState([]);
  const [activeSlide, setActiveSlide] = useState({});
  const [sliders, setSliders] = useState([]);
  const [mainSlide, setMainSlide] = useState(0);
  const [modal, setModal] = useState(false);
  const [activeSelect, setActiveSelect] = useState(0);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    toast(`So'rovingiz yuborildi ${data?.name}`);
    reset({
      name: "",
      phone: "",
      car: ""
    });
    let botMessege = `
    Assalomu aleykum😊%0A
      TestDrive uchun%0A
      Ism👤 :  ${data.name}%0A
      Telefon raqam📲 : ${data.phone}%0A
      Mashina🚙: ${data.car}%0A
      GrandCharge 🔋%0A
    `;
    axios({
      method: "get",
      url: `https://api.telegram.org/bot5638228866:AAFxClPuarT2Pjiut9PoMUZAHPxYIngjGG8/sendMessage?chat_id=-1001556574931&text=${botMessege}&parse_mode=HTML`
    });
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    afterChange: (e) => {
      setMainSlide(e);
    }
  };
  useEffect(() => {
    document.querySelector(".animate-slider").style.display = "none";
    setTimeout(() => {
      document.querySelector(".animate-slider").style.display = "block";
    }, 40);
  }, [topSlider]);
  useEffect(() => {
    getRestApi("products/", setProducts);
    getRestApi("videos/", setVideos);
    getRestApi("categories/", setCategories);
    getRestApi("sliders/", setSliders);
  }, []);
  useEffect(() => {
    setTopSlider({ id: 0, product: products?.[0] });
    setActiveSlide(products?.[0]);
  }, [products]);
  function SampleNextArrow({ onClick }) {
    return (
      <div
        onClick={onClick}
        className="absolute md:bottom-[4vw] bottom-[0vw] md:left-[20vw] left-[25vw] hover hover:bg-[#028146] hover:text-[#ffffff]:bg-[#5AFFB3] md:w-[3vw] md:h-[3vw] w-[8vw] h-[8vw] flex justify-center items-center rounded-[5vw] z-[30] cursor-pointer"
      >
        <ArrowRight />
      </div>
    );
  }
  function SamplePrevArrow({ onClick }) {
    return (
      <div
        onClick={onClick}
        className="absolute md:bottom-[4vw] bottom-[0vw] left-[15vw] hover hover:bg-[#028146] hover:text-[#ffffff]:bg-[#5AFFB3] md:w-[3vw] md:h-[3vw] w-[8vw] h-[8vw] flex justify-center items-center rounded-[5vw] z-[30]  cursor-pointer"
      >
        <ArrowLeft />
      </div>
    );
  }

  function NextVideo({ onClick }) {
    return (
      <div
        onClick={onClick}
        className="absolute bottom-[50%] bg-[#ffffff27]  w-[8vw] h-[8vw] md:right-[-6vw] right-[-10vw] hover hover:bg-[#028146] hover:text-[#ffffff]:bg-[#5AFFB3] md:w-[4vw] md:h-[4vw] flex justify-center items-center rounded-[5vw] z-[30] cursor-pointer"
      >
        <ArrowRight />
      </div>
    );
  }
  function PrevVideo({ onClick }) {
    return (
      <div
        onClick={onClick}
        className="absolute bottom-[50%] bg-[#ffffff27] w-[8vw] h-[8vw]  md:left-[-6vw]  left-[-10vw] hover hover:bg-[#028146] hover:text-[#ffffff]:bg-[#5AFFB3] md:w-[4vw] md:h-[4vw] flex justify-center items-center rounded-[5vw] z-[30] cursor-pointer"
      >
        <ArrowLeft />
      </div>
    );
  }
  const settingsBottomSlider = {
    dots: false,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (e) => {
      setActiveSlide(products?.[e]);
    }
  };
  const videoSlider = {
    dots: false,
    infinite: true,
    speed: 1200,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    nextArrow: <NextVideo />,
    prevArrow: <PrevVideo />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  function calculator(km) {
    setChargeCar(360 * km);
    setPetrolCar(10000 * km);
  }
  const { language, currentLang } = useGlobal();
  return (
    <div className="bg-charger">
      <ToastContainer />

      {modal && (
        <Modal
          car={topSlider?.product?.[`name_${currentLang}`]}
          close={() => setModal(false)}
        />
      )}
      <div>
        {window.scrollY > 400 && (
          <Scrollspy
            className="fixed flex flex-col left-[4vw] bottom-[4vw] z-[90] md:text-[1.2vw] text-[3.2vw]"
            items={["one", "two", "three"]}
            currentClassName="active__nav"
          >
            <li className="opacity-40 hover:opacity-75 relative">
              <a href="#one" className="text-[#fff]">
                {language["page2"]}
              </a>
            </li>
            <li className="opacity-40 hover:opacity-75 relative">
              <a href="#two" className="text-[#fff]">
                {language["page3"]}
              </a>
            </li>
            {/* <li className="opacity-40 hover:opacity-75 relative">
              <a href="#three" className="text-[#fff]">
                {language["page4"]}
              </a>
            </li> */}
          </Scrollspy>
        )}
      </div>
      <div className="relative">
        <div className="absolute md:p-0 p-[10vw_5vw] top-[10vw] flex md:flex-row flex-col w-[80%] justify-between left-[10%] z-[40] md:items-stretch items-start">
          <div className="flex md:flex-row flex-col md:px-[3vw]  md:p-[1vw] rounded-[1vw] w-[50vw] justify-between">
            <div className="flex items-center gap-[1vw]">
              <p className="text-[#002C4E] md:text-[3vw] text-[6vw] craftsemibold">
                {sliders?.[mainSlide]?.[`text_${currentLang}`]}
              </p>
            </div>

            <div className="flex items-center gap-[1vw] ">
              <BagSvg />{" "}
              <p className="text-[#002C4E] md:text-[1.2vw] text-[3.2vw] md:w-[11vw] w-[32vw]">
                bitta quvatlanish bilan 600 km
              </p>
            </div>
          </div>
          <Link to={"/category"} className="relative md:bottom-0 bottom-[-65vh]">
            <button className="md:text-[#000000] text-[#fff] md:text-[1.8vw] text-[3.8vw] flex items-center md:gap-[2vw] gap-[4vw] afters after:border-[#35C884]">
              {language["page1"]} <ArrowSvg />
            </button>
            <Notification text={"Mashina haqida batafsil"} bottom={true} />
          </Link>
        </div>
        <Slider {...settings}>
          {sliders?.map((item, i) => (
            <div key={i} className="h-[100vh] relative">
              <img
                src={item?.image}
                className="w-full h-[100vh] object-cover"
                alt=""
              />
            </div>
          ))}
        </Slider>
      </div>

      <div className="pt-[6vw]" id="one">
        <div className="bg-[#ffffff1a] md:rounded-[1vw] rounded-[3vw] md:w-[70%] w-[80%] m-auto p-[2vw]">
          <h1 className="md:text-[2vw] text-[4vw] text-[#fff] text-center capitalize craftsemibold mb-[2vw]">
            {language["page2"]}
          </h1>
          <div className="flex md:flex-col flex-col-reverse gap-[2vw]">
            {/* <div className="flex gap-[2vw] justify-between">
              <Link
                to="/category"
                className="bg-[#9c98953d] md:w-full w-[48%]  transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
              >
                <img
                  src="https://robbreport.com/wp-content/uploads/2019/06/charge-mustang.jpg"
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <CategoryTruck />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  BYD
                </h1>
              </Link>
              <Link
                to="/category"
                className="bg-[#9c98953d] md:w-full w-[48%]  transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
              >
                <img
                  src="https://robbreport.com/wp-content/uploads/2019/06/charge-mustang.jpg"
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <TejamkorCar />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  Hong Qi
                </h1>
              </Link>
            </div> */}
            <div className="grid md:grid-cols-3 grid-cols-2  gap-[2vw]">
              {categories?.map((item, i) => (
                <Link
                  key={i}
                  to="/category"
                  onClick={() => localStorage.setItem("category", item?.id)}
                  className="bg-[#9c98953d] w-full  transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
                >
                  <img
                    src={item?.image2}
                    className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                    alt=""
                  />
                  {/* <FamilyCar /> */}
                  <img
                    src={item?.icon}
                    className="w-full h-[8vw] object-contain"
                    alt=""
                  />
                  <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                    {item?.[`name_${currentLang}`]}
                  </h1>
                </Link>
              ))}

              {/* <Link
                to="/category"
                className="bg-[#9c98953d] md:w-full w-[48%]  transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
              >
                <img
                  src="https://robbreport.com/wp-content/uploads/2019/06/charge-mustang.jpg"
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <CategoryTruck />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  LeapMotor
                </h1>
              </Link>
              <Link
                to="/category"
                className="bg-[#9c98953d] md:w-full w-[48%]  transition-[.5s] p-[3vw] py-[5vw] cursor-pointer flex flex-col items-center gap-[1vw] rounded-[1vw] relative overflow-hidden"
              >
                <img
                  src="https://robbreport.com/wp-content/uploads/2019/06/charge-mustang.jpg"
                  className="w-full h-[100%] absolute top-0 left-0 object-cover  transition-[.5s] opacity-0 hover:opacity-100"
                  alt=""
                />
                <FastCar />
                <h1 className="text-[#fff] md:text-[1.5vw] text-[3vw] text-center">
                  Neta
                </h1>
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-[6vw]" id="two">
        <div className="bg-[#ffffff1a] rounded-[1vw] md:w-[70%] w-[80%] m-auto md:p-[2vw] p-[4vw]">
          <h1 className="md:text-[2vw] text-[4vw] text-[#fff] text-center capitalize craftsemibold mb-[2vw]">
            {language["page3"]}
          </h1>
          <div className="bg-[#ffffff1a] md:h-[30vw] h-[70vw] relative md:rounded-[1vw] rounded-[2vw] w-full m-auto md:p-[2vw] pr-0">
            <img
              src={require("../../assets/img/bgblue.png")}
              className="absolute top-0 left-0 h-[100%] z-10 md:block hidden"
            />
            <div className="relative flex items-center md:flex-row flex-col overflow-hidden">
              <div className="md:w-[55%] w-full z-20  md:flex flex-col justify-between md:h-[27vw] h-[44vw] md:bg-[#ffffff00] hover:text-[#ffffff] bg-[#5AFFB3] rounded-[2vw]">
                <div className="flex justify-between md:p-0 p-[2vw]">
                  <h1 className="md:text-[2vw] text-[4vw] craftsemibold text-[#002C4E]">
                    {topSlider?.product?.[`name_${currentLang}`]}
                  </h1>
                </div>
                <img
                  src={topSlider?.product?.image}
                  className="md:w-[90%] w-[40vw] md:static absolute right-[0vw] animate-slider"
                  alt=""
                />
                <div className="flex gap-[2vw] md:flex-row flex-col md:p-0 p-[2vw]">
                  <div className="flex  md:gap-[.4vw] gap-[1.5vw] items-center text-[#002C4E]">
                    <Speedometer />
                    <div className="flex flex-col">
                      <h1 className="md:text-[1.1vw] text-[3.1vw] font-medium md:leading-[1.1vw] leading-[3.1vw]">
                        {topSlider?.product?.max_speed}
                      </h1>
                      <p className="text-[1.7vw] leading-[2.7vw] md:text-[.7vw] md:leading-[.7vw]">
                        {language["key1"]}
                      </p>
                    </div>
                  </div>
                  <div className="flex  md:gap-[.4vw] gap-[1.5vw] items-center text-[#002C4E]">
                    <Men />
                    <div className="flex flex-col">
                      <h1 className="md:text-[1.1vw] text-[3.1vw] font-medium md:leading-[1.1vw] leading-[3.1vw]">
                        {topSlider?.product?.people}
                      </h1>
                      <p className="text-[1.7vw] leading-[2.7vw] md:text-[.7vw] md:leading-[.7vw]">
                        {language["key2"]}
                      </p>
                    </div>
                  </div>
                  <div className="flex  md:gap-[.4vw] gap-[1.5vw] items-center text-[#002C4E]">
                    <SliceBattery />
                    <div className="flex flex-col">
                      <h1 className="md:text-[1.1vw] text-[3.1vw] font-medium md:leading-[1.1vw] leading-[3.1vw]">
                        {topSlider?.product?.capacity} WaT
                      </h1>
                      <p className="text-[1.7vw] leading-[2.7vw] md:text-[.7vw] md:leading-[.7vw]">
                        {language["key3"]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-[44%] w-full">
                <div className="relative md:w-[120%] w-[100%] md:right-[20%]">
                  <ScrollContainer
                    className="scrollContainer"
                    horizontal={true}
                  >
                    <div className="flex py-[2vw]">
                      {products?.map((item, id) => (
                        <div
                          id={"btn-" + id}
                          onClick={() => {
                            setTopSlider({ id: id, product: item });
                            document
                              .querySelector(".scrollContainer")
                              .scrollTo(
                                document.getElementById(`btn-${id}`)
                                  .offsetLeft + 100,
                                0
                              );
                          }}
                        >
                          <div
                            className={`bg-slide-blue transition-[.4s] md:w-[10vw] w-[30vw] py-[1vw]  opacity-60 hover:opacity-100 cursor-pointer`}
                            style={{
                              transform:
                                topSlider.id === item
                                  ? "scale(1.1)"
                                  : "scale(.9)"
                            }}
                          >
                            <img
                              src={item?.image}
                              className="md:w-[10vw] w-[30vw] md:h-[10vw] h-[20vw] object-contain z-index-5000000"
                              alt=""
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </ScrollContainer>
                </div>
                <div className="flex gap-[5vw] w-full absolute md:right-[2vw] right-[-2vw] md:justify-end md:bottom-[1vw] bottom-[42%] z-[60] text-[1.2vw] md:text-[#fff] text-[#fff]">
                  {/* <div className="md:flex hidden items-end gap-[1vw]">
                    <p className="font-thin"> {language["cost"]}: </p>
                    <h1 className="text-[1.4vw] font-medium">
                      {" "}
                      {topSlider?.product?.price} $
                    </h1>
                  </div> */}
                  <button
                    className="md:text-[1.2vw] text-[3vw] rounded-[1vw] py-[.4vw] px-[2vw] btn-rotate md:bg-transparent bg-[#002C4E]"
                    onClick={() => setModal(true)}
                  >
                    {language["buy_now"]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-[6vw]" id="calc">
        <div className="md:w-[70%] w-full md:pl-0 pl-[5%] m-auto md:overflow-visible overflow-hidden">
          <h1 className="md:text-[2vw] text-[4vw] text-[#fff] text-center capitalize craftsemibold mb-[2vw]">
            {language["calculator"]}
          </h1>
          <div className="flex md:text-[1vw] md:flex-row flex-col text-[2.5vw] text-[#fff] relative md:overflow-visible overflow-hidden">
            <div className="flex md:flex-col   flex-col-reverse md:w-[30%] w-[50%] mb-[6vw]">
              <p>{language["c1"]}</p>
              <h1 className="md:text-[1.6vw] text-[3.6vw] md:mt-[3vw] font-medium">
                BYD model song
              </h1>
              <div className=" mt-[1vw] gap-[4vw] text-[.8vw] md:flex hidden">
                <div className="flex items-center gap-[.4vw]">
                  <KmWat />
                  <div className="flex flex-col">
                    <p>{language["c22"]}</p>
                    {/* <h1 className="text-[1.2vw] leading-[1.6vw] font-medium">
                      30 kv energiya
                    </h1> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="img">
              <img
                src={require("../../assets/img/solishtirish.png")}
                className="md:w-[30vw] md:min-w-[30vw] md:mb-0 mb-[15vw] min-w-[180vw] md:absolute right-[5 b vw]"
                alt=""
              />
            </div>
          </div>
          <div className="flex relative w-full gap-[4vw]">
            <Ranger setPrice={calculator} />
            <div className="md:relative absolute md:top-0 top-[-30vw] md:p-[1vw] p-[3vw] bg-[#ffffff2d] md:rounded-[1vw] rounded-[2vw] flex items-center gap-[1vw]">
              <MoneyBag />
              <div className="flex flex-col">
                <h1 className="md:text-[1.5vw] text-[3.5vw] text-[#5AFFB3] font-medium">
                  {chargeCar}
                </h1>
                <p className="md:text-[.9vw] text-[2.9vw] text-[#5AFFB3] font-thin whitespace-nowrap">
                  o'zbek so'mi
                </p>
              </div>
            </div>
          </div>
          <div className="md:flex hidden items-center justify-between">
            <div className="flex items-center gap-[3vw] text-[1vw] text-[#fff]">
              <div className="img">
                <img
                  src="https://di-uploads-pod3.dealerinspire.com/matthewshargreaveschevy/uploads/2020/09/2020-Chevrolet-Equinox-Premier-Hero.png"
                  className="w-[20vw]"
                  alt=""
                />
              </div>
              <div className="flex flex-col ">
                <h1 className="text-[1.6vw] craftsemibold">Equinox 2022</h1>
                <div className="flex mt-[1vw] gap-[4vw]">
                  <div className="flex items-center gap-[.4vw]">
                    <Petrolist />
                    <div className="flex flex-col">
                      <p>{language["c2"]}</p>
                      {/* <h1 className="text-[1.3vw] leading-[1.6vw] craftsemibold">
                        9 L benzin
                      </h1> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-[1vw] bg-[#ffffff2d] rounded-[1vw] flex items-center gap-[1vw]">
              <MoneyBag danger />
              <div className="flex flex-col">
                <h1 className="text-[1.5vw] text-[#FF7171] font-medium">
                  {petrolCar}
                </h1>
                <p className="text-[.9vw] text-[#FF7171] font-thin">
                  o'zbek so'mi
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-[6vw]">
        <div className="bg-[#ffffff1a] md:rounded-[1vw] rounded-[2vw] md:w-[70%] w-[90%] m-auto md:p-[2vw] p-[4vw]">
          <div className="bg-[#ffffff1a] md:rounded-[1vw] rounded-[3vw]  m-auto md:p-[2vw] p-[4vw]">
            <h1 className="md:text-[2vw] text-[4vw] md:text-left text-center text-[#fff] capitalize craftsemibold mb-[2vw]">
              {language["aboutus"]}
            </h1>
            <div className="flex md:flex-row flex-col gap-[3vw]">
              <div className="flex flex-col gap-[2vw]">
                <iframe
                  className="md:w-[26vw] w-full md:h-[16vw] h-[40vw] rounded-[1vw]"
                  src="https://www.youtube.com/embed/wpCcgZNYq_A"
                  title=""
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div className="flex md:justify-start justify-center gap-[2vw]">
                  <div className="bg-[#ffffff1a] md:p-[.8vw] p-[3vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                    <FacebookSvg />
                  </div>
                  <div className="bg-[#ffffff1a] md:p-[.8vw] p-[3vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                    <TiktokSvg />
                  </div>
                  <div className="bg-[#ffffff1a] md:p-[.8vw] p-[3vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                    <InstagramSvg />
                  </div>
                  <div className="bg-[#ffffff1a] md:p-[.8vw] p-[3vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                    <YoutTubeSvg />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[2vw]">
                <p className="text-[#fff] md:text-[1.4vw] text-[3.4vw] text-justify craftregular">
                  {language["abouttextmini"]}
                </p>
                <div className="flex md:justify-start justify-center gap-[2vw] text-center">
                  <Link
                    to={"/about"}
                    className="md:p-[.5vw] md:px-[1.2vw] p-[1.5vw] px-[3.2vw] text-[#fff] hover:bg-[#ffffff6e] bg-[#ffffff2d] md:rounded-[.4vw] rounded-[2vw] md:w-auto w-full"
                  >
                    {language["a_btn1"]}
                  </Link>
                  <button
                    onClick={() => active(0)}
                    className="md:p-[.5vw] md:px-[1.2vw] p-[1.5vw] px-[3.2vw] text-[#000] hover:bg-[#fbff00] bg-[#FFCD2C] md:rounded-[.4vw] rounded-[2vw] md:w-auto w-full"
                  >
                    {language["a_btn2"]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-screen md:block hidden relative mt-[6vw]" id="three">
        <img
          src={require("../../assets/img/360.png")}
          className="w-full absolute top-0 left-0 h-screen object-cover"
          alt=""
        />
        <div className="relative">
          <h1 className="pt-[4vw] text-center text-[#fff] text-[2vw]">
            {language["g360"]}
          </h1>
          <div className="bg-[#ffffff21] backdrop-blur-md ml-[50%] w-[40vw] mt-[4vw] p-[2vw] rounded-[1vw]">
            <div>
              <p className="text-[#fff] text-[1.4vw]">
                <h1 className=" text-center text-[#fff] text-[2vw]">
                  {language["g360name"]}
                </h1>
                {language["g360text"]}
              </p>
              <div className="flex mt-[2vw] justify-around">
                <div className="flex flex-col items-center gap-[1vw]">
                  <ItunesSvg />
                  <p className="text-[#fff] text-[1.1vw] text-center">Music</p>
                </div>
                <div className="flex flex-col items-center gap-[1vw]">
                  <MessengerSvg />
                  <p className="text-[#fff] text-[1.1vw] text-center">
                    Messenger
                  </p>
                </div>
                <div className="flex flex-col items-center gap-[1vw]">
                  <YouTubeMarker />
                  <p className="text-[#fff] text-[1.1vw] text-center">
                    You tube
                  </p>
                </div>
                <div className="flex flex-col items-center gap-[1vw]">
                  <AndroidSvg />
                  <p className="text-[#fff] text-[1.1vw] text-center">
                    Android os
                  </p>
                </div>
              </div>
              <div className="flex text-center justify-center mt-[2vw] px-[3vw]">
                <a
                  target={"_blank"}
                  href="https://www.youtube.com/embed/wpCcgZNYq_A"
                  className="p-[.5vw] w-[50%] px-[1.2vw] text-[#000] bg-[#fff] hover:bg-[#5AFFB3] rounded-[.4vw]"
                >
                  {language["g360btn"]}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="md:hidden h-screen  flex justify-center items-center relative mt-[6vw]"
        id="mobile_three"
      >
        <div className="flex  md:w-[30vw] md:h-[30vw]  w-[50vw] h-[50vw] backdrop-blur-lg rounded-[50vw] flex-col justify-center items-center gap-[2vw]">
          <MobileThree />
          <p className="md:text-[3vw] text-[5vw] text-center text-[#fff]">
            {" "}
            {language["g360btn"]}{" "}
          </p>
        </div>
      </div>

      <div
        id="charge"
        className="mt-[6vw] bg-[#8bc6df1a] md:w-[70%] w-[90%] m-auto md:p-[2vw] p-[4vw] md:rounded-[1vw] rounded-[2vw]"
      >
        <h1 className="text-[#fff] md:text-[2vw] text-[4vw] md:text-left text-center mb-[2vw] craftsemibold">
          {language["zaryad"]}
        </h1>
        <div className="flex md:flex-row flex-col justify-between">
          <div className=" bg-[#8bc6df1a] md:p-[2vw] p-[4vw] md:w-[60%] rounded-[1vw]">
            <div className="flex gap-[2vw] items-center">
              <img
                src={require("../../assets/img/logocharge.png")}
                className="w-[6vw] md:block hidden"
              />
              <h1 className="text-[#fff] md:text-[1.2vw] text-[3.2vw] craftsemibold">
                {language["zaryadname"]}
              </h1>
            </div>
            <p className="md:text-[1vw] text-[2.5vw] text-[#fff] mt-[2vw] craftregular text-justify">
              {language["zaryadtext"]}
            </p>
            <div className="md:flex hidden items-center  mt-[2vw] gap-[2vw]">
              <h1 className="text-[#fff] text-[1.5vw]">
                {language["zaryadcar"]}
              </h1>
              <div className="flex gap-[.5vw] items-center">
                <WhiteSpeedmeter />
                <div className="flex flex-col items-center">
                  <h1 className="text-[#fff] text-[1vw] font-medium">
                    {language["zaryadtime"]}
                  </h1>
                  <p className="text-[1vw] text-[#fff] font-thin">
                    {language["zaryaddesc"]}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-[2vw] justify-between md:flex items-end hidden">
              <img
                src="https://www.pngmart.com/files/21/White-Tesla-Car-PNG-Transparent.png"
                className="w-[50%]"
                alt=""
              />
              <ChargerBattery />
            </div>
          </div>
          <div className="md:w-[38%]">
            {/* <iframe
              className="w-full h-[70%] rounded-[1vw] mapofcharge"
              src="https://api-maps.yandex.ru/frame/v1/-/CCUqbYdXoA?lang=en_RU"
              title=""
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            /> */}
            <MapCharges />
            <div className=" h-[25%] bg-[#8bc6df1a] p-[2vw] w-full mt-[2vw] rounded-[1vw] relative">
              <h1 className="md:text-[1.2vw] text-[3.2vw] font-medium text-[#fff]">
                BYD charger
              </h1>
              <p className="md:text-[1vw] text-[3vw] font-thin text-[#fff]">
                {language["zaryadpercentname"]}
              </p>
              <div className="flex items-center gap-[1vw]">
                <Battery />
                <p className="md:text-[1vw] text-[3vw] font-thin text-[#fff]">
                  65 wat fast charge
                </p>
              </div>
              <a
                href="https://yandex.uz/maps/10335/tashkent/geo/4272009769/?from=mapframe&ll=69.243620%2C41.364340&z=17"
                className="absolute right-0 bottom-[-3vw]"
              >
                <Location />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bgofslider relative md:h-[45vw] h-[120vw] mt-[4vw] flex flex-col justify-between">
        <div className="flex flex-col justify-between h-full">
          <div className="p-[4vw]">
            <div className="flex items-center justify-between md:w-[80%] w-full m-auto">
              <div className="flex gap-[2vw] items-center">
                {/* <img
                  src="https://mersglobal.com/wp-content/uploads/2021/08/mers-logos.png"
                  alt=""
                  className="md:w-[16vw] w-[36vw]"
                /> */}
                <div className="flex flex-col">
                  <h1 className="md:text-[2.5vw] text-[4.5vw] font-medium text-[#fff] craftsemibold">
                    {activeSlide?.[`name_${currentLang}`]}
                  </h1>
                  {/* <p className="md:text-[1.5vw] text-[2.5vw] font-thin text-[#ffffff8f] craftregular">
                    midnigth white premium edition
                  </p> */}
                </div>
              </div>
              {/* <div className="p-[1vw] bg-[#ffffff2d] rounded-[1vw] md:flex hidden items-center gap-[1vw]">
                <MoneyBag />
                <div className="flex ">
                  <h1 className="text-[1.5vw] text-[#5AFFB3] font-medium">
                    {activeSlide?.price} {language["carphoto"]}
                  </h1>
                </div>
              </div> */}
            </div>
          </div>

          <Slider {...settingsBottomSlider}>
            {products?.map((item, i) => (
              <div key={i} className=" relative overflow-hidden">
                <div>
                  <img
                    src={item?.image}
                    className="w-full md:h-[23vw] h-[50vw]  md:min-w-0 min-w-[100vw] object-contain"
                    alt=""
                  />
                </div>
              </div>
            ))}
          </Slider>
          <div className="flex flex-wrap justify-center bg-[#2A496B] md:p-[2vw] p-[3vw]  gap-[5vw] md:py-[2vw] py-[4vw] md:mb-[4vw]">
            <div className="flex items-center justify-center gap-[1vw]">
              <WhiteSpeedmeter />
              <div className="flex flex-col">
                <h1 className="text-[#fff] md:text-[1.8vw] text-[3.8vw] md:leading-[2vw] leading-[4vw] font-medium">
                  {activeSlide?.max_speed}
                </h1>
                <p className="text-[#fff] md:text-[1vw] text-[2.5vw] md:leading-[1.2vw] leading-[3vw] font-thin">
                  {language["carphotokey1"]}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center gap-[1vw]">
              <MenSlider />
              <div className="flex flex-col">
                <h1 className="text-[#fff] md:text-[1.8vw] text-[3.8vw] md:leading-[2vw] leading-[4vw] font-medium">
                  {activeSlide?.people}
                </h1>
                <p className="text-[#fff] md:text-[1vw] text-[2.5vw] md:leading-[1.2vw] leading-[3vw] font-thin">
                  {language["carphotokey2"]}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center gap-[1vw]">
              <BatSlider />
              <div className="flex flex-col">
                <h1 className="text-[#fff] md:text-[1.8vw] text-[3.8vw] md:leading-[2vw] leading-[4vw] font-medium">
                  {activeSlide?.power} Wat
                </h1>
                <p className="text-[#fff] md:text-[1vw] text-[2.5vw] md:leading-[1.2vw] leading-[3vw] font-thin">
                  {language["carphotokey3"]}
                  fast charging
                </p>
              </div>
            </div>
            {/* <button className="text-[#fff] bg-[#ffffff2a] px-[3vw] py-[1vw] rounded-[.5vw] md:text-[1vw] text-[2.4vw]">
              {language["carphotobtn"]}
            </button> */}
          </div>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-[6vw] bg-[#8bc6df1a] md:w-[70%] w-[90%] m-auto md:p-[2vw] p-[4vw] md:rounded-[1vw] rounded-[2vw]"
      >
        <h1 className="text-[#fff] md:text-[2vw] text-[5vw] md:text-left text-center mb-[2vw] craftsemibold">
          {language["test"]}
        </h1>
        <div className="flex md:flex-row flex-col justify-between">
          <div className=" bg-[#8bc6df1a] md:p-[2vw] p-[4vw] md:w-[60%] md:rounded-[1vw] rounded-[2vw]">
            <div className="flex gap-[2vw] items-center">
              <img
                src={require("../../assets/img/logocharge.png")}
                className="w-[6vw] md:block hidden"
              />
              <h1 className="text-[#fff] md:text-[1.2vw] text-[4.2vw] craftsemibold">
                {language["testname"]}
              </h1>
            </div>
            <p className="md:text-[1vw] text-[2.9vw] text-[#fff] mt-[2vw] craftregular">
              {language["testtext"]}
            </p>
            <select
              className="w-full mt-[2vw] md:p-[1.5vw] p-[2.5vw]   bg-[#ffffff21] outline-none text-[#fff] rounded-[.6vw]"
              {...register("car")}
              onChange={(e) => {
                setActiveSelect(e.target.value);
              }}
            >
              <option value="Modelni kiriting">{language["testchoose"]}</option>
              {products?.map((item, i) => (
                <option key={i} value={i}>
                  {item[`name_${currentLang}`]}
                </option>
              ))}
            </select>
            {/* <input
              type="text"
              placeholder={language["testchoose"]}
              {...register("car")}
              className="w-full md:text-[1.2vw] text-[3vw] md:p-[1vw] p-[3vw] md:mt-[1vw] mt-[4vw] mb-[2vw]  bg-[#ffffff21] outline-none text-[#fff] rounded-[.6vw] focus:outline-[#5AFFB3]"
            /> */}
            <h1 className="text-[#fff] md:text-[1.8vw] text-[3.8vw] mt-[2vw]">
              {products?.[Number(activeSelect)]?.[`name_${currentLang}`]}
            </h1>
            <div className="flex gap-[4vw] items-center">
              <div className="flex flex-col items-center">
                <h1 className="text-[#fff] md:text-[1.7vw] text-[3.7vw] font-medium">
                  {products?.[Number(activeSelect)]?.power} Wat
                </h1>
                <p className="md:text-[1vw] text-[2vw] text-[#fff] font-thin">
                  {language["carphotokey3"]}
                </p>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-[#fff] md:text-[1.7vw] text-[3.7vw] font-medium">
                  {products?.[Number(activeSelect)]?.max_speed}
                </h1>
                <p className="md:text-[1vw] text-[2vw] text-[#fff] font-thin">
                  {language["carphotokey1"]}
                </p>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-[#fff] md:text-[1.7vw] text-[3.7vw] font-medium">
                  {products?.[Number(activeSelect)]?.people}
                </h1>
                <p className="md:text-[1vw] text-[2vw] text-[#fff] font-thin">
                  {language["carphotokey2"]}
                </p>
              </div>
            </div>
            <div className="p-[4vw]">
              <img
                src={products?.[Number(activeSelect)]?.image}
                className="w-full"
                alt=""
              />
            </div>
          </div>
          <div className="md:w-[38%]">
            <iframe
              className="w-full h-[16vw] rounded-[1vw] md:block hidden"
              src="https://www.youtube.com/embed/wpCcgZNYq_A"
              title=""
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <div className=" bg-[#8bc6df1a] md:p-[2vw] p-[4vw] w-full mt-[2vw] md:rounded-[1vw] rounded-[2vw]">
              <h1 className="text-[#fff] md:text-[1.6vw] text-[3.6vw] mb-[1.7vw]">
                {language["testform"]}
              </h1>
              <input
                type="text"
                placeholder={language["name"]}
                {...register("name", { required: true })}
                style={{
                  borderColor: errors?.name && "red"
                }}
                className="md:text-[1.2vw] text-[3vw] w-full md:p-[1vw] p-[2vw] md:mb-[1vw] mb-[2vw]  bg-[#ffffff21] outline-none text-[#fff] rounded-[.6vw] focus:outline-[#5AFFB3]"
              />
              <input
                type="number"
                placeholder={language["phone"]}
                style={{
                  borderColor: errors?.phone && "red"
                }}
                {...register("phone", { required: true, minLength: 9 })}
                className="md:text-[1.2vw] text-[3vw] w-full md:p-[1vw] p-[2vw] md:mb-[1vw] mb-[2vw]  bg-[#ffffff21] outline-none text-[#fff] rounded-[.6vw] focus:outline-[#5AFFB3]"
              />
              <p className="md:text-[.8vw] text-[2.8vw] text-[#fff] mb-[2vw] craftregular ">
                {language["testpred"]}
              </p>
              <div className="flex gap-[2vw] mt-[2vw]">
                <button className="md:p-[.5vw] p-[1.5vw] w-full px-[1.2vw] text-[#000] hover:bg-[#028146] hover:text-[#ffffff] bg-[#5AFFB3] rounded-[.4vw]">
                  {language["testbtn1"]}
                </button>
                <button
                  onClick={() => window.scrollTo(0, document.body.scrollHeight)}
                  type="button"
                  className="md:p-[.5vw] p-[1.5vw] w-full px-[1.2vw] text-[#000] bg-[#fff] hover:bg-[#5AFFB3] rounded-[.4vw]"
                >
                  {language["testbtn2"]}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="md:w-[70%] w-[90%] m-auto mt-[4vw]" id="four">
        <div className="md:p-[2vw] p-[4vw] bg-[#8bc6df0d] md:rounded-[1vw] rounded-[2vw]">
          <div className="md:p-[2vw] p-[4vw] bg-[#8bc6df0d] md:rounded-[1vw] rounded-[2vw]">
            <h1 className="text-[#fff] md:text-[2vw] text-[4vw]  mb-[2vw] craftsemibold">
              {language["videos"]}
            </h1>
            <Slider {...videoSlider}>
              {videos?.map((item, i) => (
                <div className="p-[1vw]" key={i}>
                  <video
                    controls
                    className="bg-[black] w-full md:h-[20vw] h-[40vw] rounded-[1vw]"
                    src={item?.video}
                  ></video>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default Home;
