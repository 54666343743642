import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import About from "./About";
import Category from "./Category";
import Home from "./Home";

function Grandcharge({active}) {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home active={active}/>} />
          <Route path="/about" element={<About />} />
          <Route path="/category" element={<Category />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default Grandcharge;
