import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import About from "./About";
import Category from "./Category";
import Home from "./Home";
import News from "./News";
import Product from "./Product";
import Products from "./Products";

function Grandtruck({active}) {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home active={active}/>} />
          <Route path="/products" element={<Products />} />
          <Route path="/about" element={<About />} />
          <Route path="/news/:id" element={<News />} />
          <Route path="/category" element={<Category />} />
          <Route path="/product/:id" element={<Product />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default Grandtruck;
