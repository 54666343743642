import React from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";
const coordinates = [
  [41.02706, 70.60434],
  [41.27275, 69.16912],
  [41.27266, 69.16933],
  [41.32669, 69.29285],
  [41.20084, 69.21122],
  [41.34744, 69.28614],
  [41.34383, 69.20557],
  [41.32831, 69.26524],
  [41.62283, 69.93459],
  [41.57555, 70.02099],
  [41.28827, 69.28459],
  [41.28794, 69.28457],
  [41.27803, 69.19795],
  [41.35456, 69.35492],
  [41.25816, 69.19402],
  [40.09654, 67.7775],
  [39.65665, 66.94754],
  [39.67984, 66.93839],
  [41.36703, 69.29118],
  [41.309744, 69.298442],
  [41.32231, 69.21996],
  [41.31202, 69.28508],
  [40.78565, 72.34923],
  [41.35532, 69.3756],
  [39.6799, 66.93832],
  [40.99861, 71.64416],
  [40.9921, 71.64845],
  [40.37203, 71.80882],
  [40.38385, 71.78326],
  [40.54429, 70.93967],
  [40.53772, 70.93637],
  [41.3289, 69.31332],
  [41.30482, 69.25275],
  [41.34935, 69.17809],
  [41.30749, 69.20647],
  [41.34096, 69.3591],
  [41.36359, 69.20422],
  [41.30405, 69.32207],
  [41.33934, 69.25445],
  [41.33941, 69.2542],
  [41.32801, 69.2652],
  [69.33213, 41.31298],
  [41.48929, 69.94337],
  [41.32428, 69.29584]
];
function MapCharges() {
  return (
    <YMaps>
      <div className="mapofcharge rounded-[1vw] overflow-hidden md:mt-0 mt-[2vw]">
        <Map
          defaultState={{
            center: [41.311153, 69.279729],
            zoom: 12,
            controls: []
          }}
          width="100%"
          height={window.innerWidth > 768 ? "25vw" : "45vw"}
        >
          {coordinates.map((item, i) => (
            <Placemark key={i} geometry={item} />
          ))}
        </Map>
      </div>
    </YMaps>
  );
}

export default MapCharges;
