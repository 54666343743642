import React from "react";
import { useGlobal } from "../../redux/selectors";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "axios";
function Contact() {
  const { language } = useGlobal();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    toast(`So'rovingiz yuborildi ${data?.name}`);
    reset({
      name: "",
      phone: "",
      comment: ""
    });
    let botMessege = `
    Assalomu aleykum😊%0A
      Ism👤 :  ${data.name}%0A
      Telefon raqam📲 : ${data.phone}%0A
      Izoh💬 : ${data.comment}%0A
      GrandTruck 🚚%0A
    `;
    axios({
      method: "get",
      url: `https://api.telegram.org/bot5950573944:AAFqMgsqESPhNP7KtC3FwTLlas0DSI75xuk/sendMessage?chat_id=-1001543956130&text=${botMessege}&parse_mode=HTML`
    });
  };
  return (
    <div className="py-[8vw]" id="contact">
      <ToastContainer />
      <div className="flex items-start gap-[4vw] w-[85%]">
        <div className="relative md:w-[50vw] contact-car md:h-[50vw] w-[90vw] h-[100vw] overflow-hidden">
          <img
            src={require("../../assets/img/contact.png")}
            className="absolute min-w-[100vw] h-[100vw] md:left-[-60vw] left-[-50vw] md:top-[-50vw]"
            alt=""
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex md:static absolute backdrop-blur-lg flex-col justify-center md:w-[35vw] w-[80%] md:left-0 left-[10%] bg-[#ffffff21] rounded-[2vw] gap-[2vw] md:p-[2vw] p-[4vw] md:py-[4vw] py-[8vw]"
        >
          <h1 className="md:text-[2vw] text-[4vw] font-bold text-[#fff] craftsemibold">
            {language["form_name"]}
          </h1>
          <input
            type="text"
            placeholder={language["name"]}
            {...register("name", { required: true })}
            className="bg-[#ffffff21] outline-[#FFCD2C] text-[#fff] md:rounded-[.4vw] rounded-[1vw] md:p-[1vw] p-[2vw] md:px-[2vw] px-[4vw] md:text-[1vw] text-[3vw]"
            style={{
              borderColor: errors?.name && "red"
            }}
          />
          <input
            type="number"
            placeholder={language["phone"]}
            {...register("phone", { required: true })}
            style={{
              borderColor: errors?.phone && "red"
            }}
            className="bg-[#ffffff21] outline-[#FFCD2C] text-[#fff] md:rounded-[.4vw] rounded-[1vw] md:p-[1vw] p-[2vw] md:px-[2vw] px-[4vw] md:text-[1vw] text-[3vw]"
          />
          <textarea
            cols="30"
            rows="10"
            placeholder={language["xabar"]}
            {...register("comment", { required: true })}
            className="bg-[#ffffff21] outline-[#FFCD2C] text-[#fff] md:rounded-[.4vw] rounded-[1vw] md:p-[1vw] p-[2vw] md:px-[2vw] px-[4vw] md:text-[1vw] text-[3vw]"
            style={{
              borderColor: errors?.comment && "red"
            }}
          ></textarea>
          <div className="flex gap-[1vw]">
            <button
              className="md:p-[.4vw] p-[1.2vw] px-[1.2vw] bg-[#FFCD2C] hover:bg-[#ddc86b] rounded-[.4vw] md:text-[1.6vw] text-[3vw] text-[#fff] w-full"
              type="submit"
            >
              {language["send"]}
            </button>
            <button
              className="md:p-[.4vw] p-[1.2vw] px-[1.2vw] bg-[#ffffff] rounded-[.4vw] md:text-[1.6vw] text-[3vw] text-[#000] w-full"
              type="button"
            >
              {language["boglanish"]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
