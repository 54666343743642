import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  ArrowLeft,
  ArrowRight,
  CallSvg,
  Emkost,
  FacebookSvg,
  ScrollBottom,
  SpeedometerBue,
  WhiteSpeedmeter
} from "../components/Svgs";
import { useGlobal } from "../../redux/selectors";
import { getRestApi } from "../requests";
import { Link } from "react-router-dom";
import Modal from "../components/Modal";
import Contact from "../components/Contact";

function Category() {
  const { language, currentLang } = useGlobal();
  const [products, setProducts] = useState([]);
  const [showProducts, setShowProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState({});
  const [modal, setModal] = useState(false);
  function SampleNextArrow({ onClick }) {
    return (
      <div
        onClick={onClick}
        className="absolute md:bottom-[55%] bottom-[20%] right-[12vw] bg-[#ffffff3a] hover:bg-[#5AFFB3] w-[8vw] h-[8vw] md:w-[4vw] md:h-[4vw] flex justify-center items-center rounded-[5vw] z-[30] cursor-pointer"
      >
        <ArrowRight />
      </div>
    );
  }
  function SamplePrevArrow({ onClick }) {
    return (
      <div
        onClick={onClick}
        className="absolute md:bottom-[55%] bottom-[20%] left-[12vw] bg-[#ffffff3a] hover:bg-[#5AFFB3] w-[8vw] h-[8vw] md:w-[4vw] md:h-[4vw] flex justify-center items-center rounded-[5vw] z-[30]  cursor-pointer"
      >
        <ArrowLeft />
      </div>
    );
  }
  const settingsBottomSlider = {
    dots: false,
    infinite: true,
    speed: 1300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (e) => {
      setActiveProduct(showProducts?.[e]);
    }
  };
  useEffect(() => {
    getRestApi("products/", setProducts);
  }, []);
  useEffect(() => {
    let shower = [];
    products?.map((product) => {
      if (product?.category == localStorage["category"]) {
        shower.push(product);
      }
    });
    setActiveProduct(shower?.[0]);
    setShowProducts(shower);
  }, [products]);
  return (
    <div className="bg-charger pt-[4vw]">
      {modal && (
        <Modal
          close={() => setModal(false)}
          car={activeProduct?.[`name_${currentLang}`]}
        />
      )}
      <div className="bgofslider relative md:h-[40vw] h-[90vw]  flex flex-col justify-between">
        <div>
          {" "}
          <div className=" relative md:pt-[4vw]">
            <div className="flex items-center justify-between w-[80%] m-auto">
              <div className="flex">
                <div className="flex flex-col mb-[2vw] mt-[1vw]">
                  <h1 className="md:text-[2.2vw] text-[4.2vw] font-medium text-[#fff] capitalize craftsemibold">
                    {activeProduct?.[`name_${currentLang}`]}
                  </h1>
                  <p className="md:text-[1.1vw] text-[3.1vw] font-thin text-[#ffffff8f]">
                    Home / <Link to={"/"}>Категория</Link> /{" "}
                    {activeProduct?.[`category_${currentLang}`]}
                  </p>
                </div>
              </div>
            </div>
            <Slider {...settingsBottomSlider}>
              {products?.map(
                (item, i) =>
                  item?.category == localStorage["category"] && (
                    <div className="md:p-[4vw] md:pt-[0vw] pt-[10vw]" key={i}>
                      <img
                        src={item?.image}
                        className="w-full md:h-[20vw] h-[40vw] object-contain"
                        alt=""
                      />
                    </div>
                  )
              )}
            </Slider>
            <div className="flex md:flex-nowrap flex-wrap items-center justify-center bg-[#2A496B] p-[2vw] md:py-[2vw] gap-[5vw] py-[2vw]">
              <p className="hidden md:flex gap-[2vw] items-center text-[#fff] text-[1vw]">
                <ScrollBottom />
                Scroll down for more
              </p>
              <h1 className="md:text-[2vw] text-[4vw] md:w-auto md:text-left text-center w-[100%] font-medium text-[#fff] capitalize">
                {activeProduct?.[`name_${currentLang}`]}
              </h1>
              <button
                onClick={() => {
                  window.scrollTo(0, window.innerHeight);
                }}
                className="text-[#fff] md:w-auto  w-[40%] bg-[#ffffff2a] px-[3vw] py-[1vw] md:rounded-[.5vw] rounded-[1vw] md:text-[1vw] text-[3vw]"
              >
                {language["xarakteristika"]}
              </button>
              <button
                className="text-[#fff] md:w-auto  w-[40%] bg-[#5AFFB3] px-[3vw] py-[1vw] md:rounded-[.5vw] rounded-[1vw] md:text-[1vw] text-[3vw]"
                onClick={() => setModal(true)}
              >
                {language["buy"]}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="p-[7vw] pt-[14vw] ">
        <div className="md:rounded-[1vw] rounded-[2vw] md:p-[1vw] p-[2vw]  bg-[#ffffff10]">
          <h1 className="capitalize md:text-left text-center craftsemibold md:text-[2vw] text-[4vw] text-[#fff] md:p-[1vw] p-[2vw] md:px-[2vw]">
            {language["aboutcar"]}
          </h1>
          <div className="grid md:grid-cols-[60%_38%] gap-[2vw]">
            <div className="bg-[#ffffff10] p-[2vw] rounded-[1vw] px-[4vw]">
              <h1 className="text-[#fff] md:text-[1.6vw] text-[3.6vw] font-medium my-[1vw]">
                {activeProduct?.[`name_${currentLang}`]}
              </h1>
              <div className="flex items-center justify-between mb-[2vw]">
                <div className="md:w-auto w-full">
                  <div className="flex gap-[1vw] my-[1vw] w-full">
                    <Emkost />
                    <div className="flex flex-col md:w-[20vw] w-full">
                      <div className="flex justify-between">
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff] font-thin">
                          {language["caremkost"]}
                        </p>
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff]">
                          {activeProduct?.capacity} кВт·ч
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff] font-thin">
                          {language["carxod"]}
                        </p>
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff]">
                          {activeProduct?.stock} км
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-[1vw] my-[1vw] w-full">
                    <SpeedometerBue />
                    <div className="flex flex-col w-full">
                      <div className="flex justify-between">
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff] font-thin">
                          {language["carmax"]}
                        </p>
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff]">
                          {activeProduct?.max_speed} км/ч
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff] font-thin">
                          {language["carrazgon"]}
                        </p>
                        <p className="md:text-[1.1vw] text-[3.1vw] text-[#fff]">
                          {activeProduct?.acceleration} с
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={require("../../assets/img/speed.gif")}
                  className="w-[15vw] md:block hidden rounded-[20vw]"
                  alt=""
                />
              </div>
              <img
                className="w-full"
                src={require("../../assets/img/360frame.png")}
                alt=""
              />
            </div>
            <div className="bg-[#ffffff10] p-[2vw] rounded-[1vw] md:sticky top-0">
              <h1 className="text-[#fff] md:text-[1.6vw] text-[3.6vw]  font-medium my-[1vw]">
                {language["carvideo"]}
              </h1>

              <iframe
                className="w-full md:h-[16vw] h-[40vw] md:rounded-[1vw] rounded-[2vw]"
                src="https://www.youtube.com/embed/wpCcgZNYq_A"
                title="Interyerimizga baho bering!"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />

              <h1 className="text-[#fff] md:text-[1.1vw] text-[3.1vw] font-thin mt-[1vw]">
                {language["carname"]}
              </h1>
              <h1 className="text-[#fff] md:text-[1.8vw] text-[3.8vw] font-medium mb-[2vw]">
                {activeProduct?.[`name_${currentLang}`]}
              </h1>
              {/* <h1 className="text-[#fff] md:text-[1.1vw] text-[3.1vw] font-thin mt-[1vw]">
                {language["carcost"]}
              </h1>
              <h1 className="text-[#fff] md:text-[1.8vw] text-[3.8vw] font-medium mb-[2vw]">
                {activeProduct?.price}$
              </h1> */}
              <div className="flex gap-[1vw] px-[2vw]">
                <button
                  className="p-[1vw] rounded-[.6vw] bg-[#ffffff] w-full text-[#000] md:text-[1.2vw] text-[3.2vw] mt-[1vw]"
                  onClick={() => setModal(true)}
                >
                  {language["buy"]}
                </button>
                <a href="tel:+998995535959">
                  <button className="p-[1vw] rounded-[.6vw] bg-[#5AFFB3] text-[#000] text-[1.2vw] mt-[1vw]">
                    <CallSvg />
                  </button>
                </a>
              </div>
            </div>
            <div className="bg-[#ffffff10] p-[2vw] rounded-[1vw] px-[4vw]">
              <h1 className="text-[#fff] md:text-[1.6vw] text-[3.6vw] font-medium my-[1vw]">
                {language["description"]}
              </h1>
              <p className="md:text-[1vw] text-[3vw] text-[#fff] text-justify">
                {activeProduct?.[`description_${currentLang}`]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-[7vw] pt-[0]">
        <div className="rounded-[1vw] p-[1vw] bg-[#ffffff10]">
          <h1 className="capitalize craftsemibold text-[2vw] text-[#fff] p-[1vw] px-[2vw]">
            {language["other"]}
          </h1>
          {products?.map(
            (item, i) =>
              item?.category == localStorage["category"] && (
                <div
                  key={i}
                  className="rounded-[1vw] p-[1vw] items-center bg-[#ffffff10] grid grid-cols-[40%_55%] justify-between mb-[1vw]"
                >
                  <img
                    src={item?.image}
                    alt=""
                    className="w-full rounded-[1vw] h-[18vw] object-contain"
                  />
                  <div className="flex flex-col gap-[2vw]">
                    <h1 className="text-[1.6vw] text-[#fff] font-medium">
                      {item?.[`name_${currentLang}`]}
                    </h1>
                    <p className="text-[1.4vw] text-[#fff] font-thin">
                      {item?.[`description_${currentLang}`].slice(0, 200)}....
                    </p>
                    <div className="flex gap-[1vw] w-[70%]">
                      <button
                        className="p-[.4vw] px-[1.2vw] bg-[#5AFFB3] rounded-[.4vw] text-[1.2vw] text-[#000] w-full"
                        onClick={() => {
                          setActiveProduct(showProducts?.[i]);
                          setModal(true);
                        }}
                      >
                        {language["harid"]}
                      </button>
                      <button
                        className="p-[.4vw] px-[1.2vw] bg-[#ffffff] rounded-[.4vw] text-[1.2vw] text-[#000] w-full"
                        onClick={() => {
                          setActiveProduct(showProducts?.[i]);
                          window.scrollTo(0, 0);
                        }}
                      >
                        {language["carphotobtn"]}
                      </button>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default Category;
