import axios from 'axios';

export async function getRestApi(url, setData) {
    try {
      const response = await axios.get('https://charge.abba.uz/api/' + url);
      console.log(response.data);
      setData(response?.data)
    } catch (error) {
      console.error(error);
    }
  }
  