import React from "react";
import { useGlobal } from "../../redux/selectors";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "axios";
function Modal({ truck = "", close }) {
  const { language } = useGlobal();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const onSubmit = (data) => {
    close();
    toast(`So'rovingiz yuborildi ${data?.name}`);
    reset({
      name: "",
      phone: "",
      comment: ""
    });
    let botMessege = `
    Assalomu aleykum😊%0A
      Ism👤 :  ${data.name}%0A
      Telefon raqam📲 : ${data.phone}%0A
      Mashina🚚 : ${truck}%0A
      GrandTruck yuk mashinasiga buyurtma 🚚%0A
    `;
    axios({
      method: "get",
      url: `https://api.telegram.org/bot5950573944:AAFqMgsqESPhNP7KtC3FwTLlas0DSI75xuk/sendMessage?chat_id=-1001543956130&text=${botMessege}&parse_mode=HTML`
    });
  };
  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-[#000000a2]  z-[99] flex justify-center items-center">
      <div className="absolute top-0 left-0 w-full h-screen " onClick={close} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-[3vw] rounded-[1vw] bg-[#00000034] backdrop-blur-xl w-full max-w-[600px] flex flex-col gap-[1vw]"
      >
        <input
          type="text"
          className="border border-[#fff] bg-transparent text-[#fff] p-[1vw] rounded-[1vw] w-full"
          placeholder={language["name"]}
          {...register("name", { required: true })}
          style={{
            borderColor: errors?.name && "red"
          }}
        />
        <input
          type="number"
          className="border border-[#fff] bg-transparent text-[#fff] p-[1vw] rounded-[1vw] w-full"
          placeholder={language["phone"]}
          {...register("phone", { required: true })}
          style={{
            borderColor: errors?.phone && "red"
          }}
        />
        <button
          className="md:p-[1vw] p-[1.5vw] w-full px-[1.2vw] text-[#000] bg-[#FECE00] rounded-[1vw] md:text-[1vw] text-[3vw]"
          type="submit"
        >
          {language["send"]}
        </button>
      </form>
    </div>
  );
}

export default Modal;
