import React, { useState } from "react";

import "./assets/main.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import Grandtruck from "./grandtruck/screens/Grandtruck";
import Grandcharge from "./grandcharge/screens/Grandcharge";
import imgTruck from "./assets/img/wel1.png";
import imgCharge from "./assets/img/wel2.png";
import { useGlobal } from "./redux/selectors";
import { ToastContainer } from "react-toastify";
function App() {
  const [active, setActive] = useState(false);
  const { language } = useGlobal();
  return (
    <div>
      <ToastContainer />
      <>
      {/* <Provider store={store}> */}
        {active === 0 ? (
          <Grandtruck active={setActive}/>
        ) : active === 1 ? (
          <Grandcharge active={setActive}/>
        ) : (
          <div className="flex overflow-hidden h-screen ">
            <div
              className="md:w-[60%] w-full absolute z-[10] md:h-screen h-[60%] left  transition-[.4s] welcome flex items-center justify-center md:p-[20vw]"
              onClick={() => setActive(0)}
              style={{
                background: `url(${imgTruck})`,
                backgroundPosition: "left"
              }}
            >
              <div className="flex flex-col items-center ">
                <img
                  className="md:w-[16vw] w-[32vw]"
                  src={require("./assets/img/logo.png")}
                  alt=""
                />
                <p className="md:text-[1vw] text-[3vw] relative bottom-[-10vw] text-[#fff] mt-[2vw]">
                  {language["kirish1"]}
                </p>
              </div>
            </div>
            <div
              className="md:w-[60%] w-full absolute z-[9] md:h-screen h-[60%] md:right-0 bottom-0  transition-[.4s] welcome flex items-center justify-center md:p-[20vw]"
              style={{
                background: `url(${imgCharge})`,
                backgroundPosition: "right"
              }}
              onClick={() => setActive(1)}
            >
              <div className="flex flex-col items-center ">
                <img
                  className="md:w-[16vw] w-[32vw]"
                  src={require("./assets/img/logocharge.png")}
                  alt=""
                />
                <p className="md:text-[1vw] text-[3vw]  relative bottom-[-10vw] text-[#fff] mt-[2vw]">
                  {language["kirish2"]}
                </p>
              </div>
            </div>
            <img
              src={require(`./assets/img/${
                window.innerWidth > 768 ? "absolute.png" : "absolute-mobile.png"
              }`)}
              className="absolute z-[90] md:left-[32%] left-0 md:w-[35vw] w-[100vw] md:h-screen h-[54vw]  md:top-0 top-[40%] absolute__home transition-[.4s]"
              alt=""
            />
          </div>
        )}
      </>
    </div>
  );
}

export default App;
