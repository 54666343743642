import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobal } from "../../redux/selectors";
import Modal from "../components/Modal";
import {
  FacebookSvg,
  InstagramSvg,
  TiktokSvg,
  YoutTubeSvg
} from "../components/Svgs";
import { getRestApi } from "../requests";

function Product() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const { currentLang, language } = useGlobal();
  useEffect(() => {
    getRestApi(`product/${id}/`, setData);
  }, []);

  return (
    <div className="bg-truck">
      {modal && (
        <Modal
          close={() => {
            setModal(false);
          }}
          truck={data?.[`name_${currentLang}`]}
        />
      )}
      <div className="p-[7vw] md:pt-[14vw] pt-[24vw] ">
        <div className="md:rounded-[1vw] rounded-[2vw] md:p-[1vw] p-[4vw] bg-[#ffffff10]">
          <h1 className="capitalize craftsemibold md:text-[2vw] text-[4vw] text-[#fff] p-[1vw] px-[2vw]">
            {data?.[`category_${currentLang}`]}
          </h1>
          <div className="grid md:grid-cols-[60%_38%] gap-[2vw] items-center">
            <div className="flex flex-col gap-[2vw]">
              <div className="bg-[#ffffff10] md:p-[2vw] p-[4vw]  md:rounded-[1vw] rounded-[2vw] px-[4vw]">
                <h1 className="text-[#fff] md:text-[1.4vw] text-[3.4vw] font-medium my-[1vw]">
                  {data?.[`name_${currentLang}`]}
                </h1>
                <p className="text-[#fff] md:text-[1vw] text-[3vw] font-thin text-justify">
                  {data?.[`description_${currentLang}`]}
                </p>
              </div>
              <div className="grid grid-cols-3 gap-[2vw]">
                {data?.images?.slice(0, 6).map((item, i) => (
                  <div key={i}>
                    <label htmlFor={i}>
                      <input
                        type="checkbox"
                        className="open-image hidden"
                        name={i}
                        id={i}
                      />
                      {/* <img
                        src={item?.image}
                        alt=""
                        className={`w-[100%] h-[20vw] object-cover rounded-[1vw] transition-[.4s] cursor-search`}
                      /> */}
                      <img
                        className="w-full h-[10vw] rounded-[1vw] object-cover cursor-search"
                        key={i}
                        src={item}
                      />
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-[#ffffff10] md:p-[2vw] p-[4vw] md:rounded-[1vw] rounded-[2vw]">
              <h1 className="text-[#fff] md:text-[1.4vw] text-[3.4vw] font-medium my-[1vw]">
                {data?.[`name_${currentLang}`]}
              </h1>
              <img
                src={data?.images?.[0]}
                className="w-full md:h-[16vw] h-[40vw] md:rounded-[1vw] rounded-[2vw] object-cover"
                alt=""
              />
              <h1 className="text-[#fff] md:text-left text-center  md:text-[1.4vw] text-[3.4vw] font-medium my-[1vw]">
                Ижтимоий тармокларимиз
              </h1>
              <div className="flex md:justify-between justify-center md:gap-0 gap-[4vw]">
                <div className="rounded-[10vw] border border-transparent hover:border-[#fff] cursor-pointer p-[1vw] bg-[#ffffff44]">
                  <FacebookSvg />
                </div>
                <div className="rounded-[10vw] p-[1vw] border border-transparent hover:border-[#fff] cursor-pointer bg-[#ffffff44]">
                  <InstagramSvg />
                </div>
                <div className="rounded-[10vw] p-[1vw] border border-transparent hover:border-[#fff] cursor-pointer bg-[#ffffff44]">
                  <YoutTubeSvg />
                </div>
                <div className="rounded-[10vw] p-[1vw] border border-transparent hover:border-[#fff] cursor-pointer bg-[#ffffff44]">
                  <TiktokSvg />
                </div>
              </div>
              <button
                className="p-[1vw] md:rounded-[.4vw] rounded-[1.8vw] bg-[#ffffff44] w-full text-[#fff] md:text-[1.2vw] text-[3.2vw] md:mt-[1vw] mt-[8vw] hover:bg-[#FECE00]"
                onClick={() => setModal(true)}
              >
                {language["buy_now"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
