import React, { useState } from "react";
import { CloseSvg, ToBottomArrow } from "./Svgs";

function Accordion({ data, lang }) {
  const [active, setActive] = useState(false);
  return (
    <div className=" border-b overflow-hidden">
      <div
        onClick={() => setActive(!active)}
        className={`flex p-[1vw] px-[3vw] justify-between items-center ${
          active ? "bg-[#fff]" : "bg-[#fffcf87e]"
        }  rounded-[1vw] cursor-pointer`}
      >
        <h1 className="craftsemibold md:text-[1.4vw] text-[2.4vw] font-bold">
          {data?.[`question_${lang}`]}
        </h1>

        {active ? <CloseSvg /> : <ToBottomArrow />}
      </div>
      {active && (
        <p className="md:text-[1.2vw] text-[2.2vw] p-[2vw] px-[4vw]">
          {data?.[`answer_${lang}`]}
        </p>
      )}
    </div>
  );
}

export default Accordion;
