import React, { useState } from "react";
import Slider from "react-input-slider";
import { RangeStart } from "../../grandcharge/components/Svgs";

function Ranger({ setPrice }) {
  const [state, setState] = useState({ x: 10 });
  return (
    <div className="relative flex items-start gap-[.3vw]">
      <div
        className="absolute text-[#fff] md:text-[1.2vw] text-[3.2vw] z-50 md:bottom-[2vw] bottom-[5vw] whitespace-nowrap"
        style={{ left: state.x - 3 + "%" }}
      >
        {state.x * 100} km
      </div>
      <RangeStart />
      <Slider
        axis="x"
        x={state.x}
        onChange={({ x }) => {
          setState((state) => ({ ...state, x }));
          setPrice(x * 10);
        }}
        styles={{
          track: {
            backgroundColor: "#979797",
            width: window.innerWidth > 768 ? "50vw" : "75vw"
          },
          active: {
            backgroundColor: "#5AFFB3"
          },
          thumb: {
            width: window.innerWidth > 768 ? "1.5vw" : "4vw",
            height: window.innerWidth > 768 ? "1.5vw" : "4vw",
            backgroundColor: "#5AFFB3"
          },  
          disabled: {
            opacity: 0.5
          }
        }}
      />
      <RangeStart />
    </div>
  );
}

export default Ranger;
