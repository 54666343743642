import React, { useEffect, useState } from "react";
import { useGlobal } from "../../redux/selectors";
import { getRestApi } from "../requests";
import { Link } from "react-router-dom";
function Products() {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const { currentLang, language } = useGlobal();
  useEffect(() => {
    getRestApi("product/", setProducts);
    getRestApi("category/", setCategory);
  }, []);

  return (
    <div className="bg-truck">
      <div className="md:p-[7vw] p-[5vw] md:pt-[14vw] pt-[24vw]">
        <h1 className="capitalize craftsemibold md:text-[2vw] text-[4vw] text-[#fff]">
          {category?.[`name_${currentLang}`]}
          {category?.map(
            (item, i) =>
              localStorage["category"] == item?.id &&
              item?.[`name_${currentLang}`]
          )}
        </h1>
        <div className="bg-[#ffffff10] md:p-[2vw] p-[4vw] rounded-[1vw] mt-[1vw] grid md:grid-cols-3 grid-cols-2 md:gap-[1vw] gap-[2vw]">
          {products?.map(
            (item, i) =>
              localStorage["category"] == item?.category && (
                <Link
                  to={`/product/${item?.id}`}
                  className="bg-[#ffffff10] p-[1vw] rounded-[1vw] border-[.1vw] border-transparent hover:border-[#fff]"
                  key={i}
                >
                  <img
                    src={item?.image1}
                    alt=""
                    className="h-[20vw] rounded-[1vw] object-cover w-full"
                  />
                  <div className="p-[1vw]">
                    <h1 className="md:text-[1.6vw] text-[2.7vw] text-[#fff] whitespace-nowrap text-ellipsis overflow-hidden">
                      {item[`name_${currentLang}`]}
                    </h1>
                    <p className="md:text-[1.2vw] text-[2.3vw] text-[#ffffff5b] whitespace-nowrap text-ellipsis overflow-hidden">
                      {item[`description_${currentLang}`]}
                    </p>
                    <button className="bg-[#ffffff13] text-[#fff] hover:bg-[#FECE00] hover:text-[#000] p-[.7vw] rounded-[.5vw] w-full font-medium mt-[1vw] md:text-[1.4vw] text-[3vw]">
                      {language["buy_now"]}
                    </button>
                  </div>
                </Link>
              )
          )}
        </div>
      </div>
    </div>
  );
}

export default Products;
