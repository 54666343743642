import React from "react";
import {
  InstagramSvg,
  TiktokSvg,
  YoutTubeSvg
} from "../../grandtruck/components/Svgs";
import { useGlobal } from "../../redux/selectors";
import Contact from "../components/Contact";
import { FacebookSvg } from "../components/Svgs";

function About() {
  const { language } = useGlobal();

  return (
    <div className="bg-charger">
      <div className="p-[7vw] pt-[14vw] ">
        <div className="md:rounded-[1vw] rounded-[2vw] md:p-[1vw] p-[4vw] bg-[#ffffff10]">
          <h1 className="capitalize craftsemibold md:text-[2vw] text-[4vw] text-[#fff] p-[1vw] px-[2vw]">
            {language["aboutname"]}
          </h1>
          <div className="grid md:grid-cols-[60%_38%] gap-[2vw]">
            <div className="bg-[#ffffff10] md:p-[2vw] p-[4vw]  md:rounded-[1vw] rounded-[2vw] px-[4vw]">
              <h1 className="text-[#fff] md:text-[1.4vw] text-[3.4vw] font-medium my-[1vw]">
                {language["f2"]}
              </h1>
              <p className="text-[#fff] md:text-[1vw] text-[3vw] font-thin text-justify">
                {language["abouttext"]}
              </p>
            </div>
            <div className="bg-[#ffffff10] md:p-[2vw] p-[4vw] md:rounded-[1vw] rounded-[2vw]">
              <h1 className="text-[#fff] md:text-[1.4vw] text-[3.4vw] font-medium my-[1vw]">
                {language["aboutvideo"]}
              </h1>
              <iframe
                className="w-full md:h-[16vw] h-[40vw] md:rounded-[1vw] rounded-[2vw]"
                src="https://www.youtube.com/embed/wpCcgZNYq_A"
                title=""
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <h1 className="text-[#fff] md:text-left text-center  md:text-[1.4vw] text-[3.4vw] font-medium my-[1vw]">
                {language["social"]}
              </h1>
              <div className="flex md:justify-between justify-center md:gap-0 gap-[4vw]">
                <div className="bg-[#ffffff1a] p-[.8vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                  <FacebookSvg />
                </div>
                <div className="bg-[#ffffff1a] p-[.8vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                  <TiktokSvg />
                </div>
                <div className="bg-[#ffffff1a] p-[.8vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                  <InstagramSvg />
                </div>
                <div className="bg-[#ffffff1a] p-[.8vw] rounded-[5vw] border border-transparent hover:border-[#fff] cursor-pointer">
                  <YoutTubeSvg />
                </div>
              </div>
              <button
                className="p-[1vw] md:rounded-[.4vw] rounded-[1.8vw] bg-[#ffffff44] w-full text-[#fff] md:text-[1.2vw] text-[3.2vw] md:mt-[1vw] mt-[8vw]"
                onClick={() => window.scrollTo(0, window.innerHeight + (window.innerHeight /2))}
              >
                {language["aboutsend"]}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
}

export default About;
