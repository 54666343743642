import React, { useRef } from "react";

import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";

function DraggbleSlider({data, lang}) {
  const ref = useRef(null);
  return (
    <div>
      <ScrollContainer className="scroll-container" horizontal={true}>
        <div className="flex mt-[3vw] md:gap-[1vw] gap-[3vw]">
          {data?.map((item, i) => (
            <Link to={'/news/' + item?.id} className="md:min-w-[16vw] min-w-[30vw] overflow-hidden relative group rounded-[1vw] cursor-pointer transition-[.4s] md:hover:min-w-[24vw] hover:min-w-[46vw]">
              <img
                key={i}
                src={item?.image}
                alt=""
                className={`w-full md:h-[20vw] h-[40vw] object-cover`}
              />
              <div className="absolute w-full z-40 bottom-0 opacity-0 text-[#fff] md:text-[1.2vw] text-[3.2vw] bg-gradient-to-t from-black to-[#ffffff00] md:p-[1vw] p-[2vw] group-hover:opacity-100 transition-[.5s]">
                <h1 className="craftsemibold font-medium  whitespace-nowrap text-ellipsis overflow-hidden w-full">
                 {item?.[`name_${lang}`]}
                </h1>
                <p className="font-thin whitespace-nowrap text-ellipsis overflow-hidden w-full">
                {item?.[`description_${lang}`]}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </ScrollContainer>
    </div>
  );
}

export default DraggbleSlider;
