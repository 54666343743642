import React from "react";
import { useGlobal } from "../../redux/selectors";
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "axios";
function Modal({ car = "", close }) {
  const { language } = useGlobal();
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    close();
    toast(`So'rovingiz yuborildi ${data?.name}`);
    reset({
      name: "",
      phone: "",
      comment: ""
    });
    let botMessege = `
    Assalomu aleykum😊%0A
      Ism👤 :  ${data.name}%0A
      Telefon raqam📲 : ${data.phone}%0A
      Mashina : ${car}%0A
      GrandTCharge mashinasiga buyurtma %0A
    `;
    axios({
      method: "get",
      url: `https://api.telegram.org/bot5638228866:AAFxClPuarT2Pjiut9PoMUZAHPxYIngjGG8/sendMessage?chat_id=-1001556574931&text=${botMessege}&parse_mode=HTML`
    });
  };
  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-[#000000a2]  z-[99] flex justify-center items-center">
      <div className="absolute top-0 left-0 w-full h-screen " onClick={close} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-[3vw] rounded-[1vw] bg-[#00000034] backdrop-blur-xl w-full max-w-[600px] flex flex-col gap-[1vw]"
      >
        <input
          type="text"
          className="border border-[#fff] bg-transparent text-[#fff] p-[1vw] rounded-[1vw] w-full"
          placeholder={language["name"]}
          {...register("name", { required: true})}
        />
        <input
          type="number"
          className="border border-[#fff] bg-transparent text-[#fff] p-[1vw] rounded-[1vw] w-full"
          placeholder={language["phone"]}
          {...register("phone", { required: true})}
        />
        <button
          className="md:p-[1vw] p-[1.5vw] w-full px-[1.2vw] text-[#000] bg-[#5AFFB3] rounded-[1vw] md:text-[1vw] text-[3vw]"
          type="submit"
        >
          {language["send"]}
        </button>
      </form>
    </div>
  );
}

export default Modal;
