import React from "react";
import { useGlobal } from "../../redux/selectors";

function Footer() {
  const { language } = useGlobal();
  return (
    <footer>
      <div className="md:p-[2vw] p-[6vw] px-[6vw]">
        <div className="flex gap-[10vw] justify-center items-center">
          <div className="flex flex-col md:w-auto w-full ">
            <div className="flex md:flex-row flex-col md:items-center gap-[1vw] md:w-[20vw] mb-[2vw]">
              <img
                src={require("../../assets/img/logo.png")}
                className="md:w-[10vw] w-[25vw]"
              />
              <p className="md:text-[1vw] text-[3vw] text-[#fff]">
                {language["slogan2"]}
              </p>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2999.975252349289!2d69.22717231542138!3d41.24409697927747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x78335adb059f762f!2zNDHCsDE0JzM4LjgiTiA2OcKwMTMnNDUuNyJF!5e0!3m2!1sen!2s!4v1671104073422!5m2!1sen!2s"
              style={{ border: 0 }}
              className="md:w-[25vw] md:h-[15vw] w-[38vw] h-[38vw] rounded-[1vw]"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          <div className="flex md:flex-row flex-col  md:w-auto w-full md:gap-[10vw] gap-[4vw] justify-center md:items-center">
            <div className="flex flex-col gap-[.6vw]">
              <h1 className="font-bold md:text-[1.7vw] text-[2.7vw] capitalize craftsemibold text-[#fff]">
                {language["company2"]}
              </h1>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              >
                {language["fo1"]}
              </a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              >
                {language["fo2"]}
              </a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              >
                {language["fo3"]}
              </a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              >
                {language["fo4"]}
              </a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] "
              >
                {language["fo5"]}
              </a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              >
                {language["fo6"]}
              </a>
            </div>
            <div className="flex flex-col gap-[.6vw]">
              <h1 className="font-bold md:text-[1.7vw] text-[2.7vw] capitalize craftsemibold text-[#fff]">
                {language["aloqa2"]}
              </h1>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              >
                {language["fo7"]}
              </a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              >
                {language["fo8"]}
              </a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              >
                {language["fo9"]}
              </a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              >
                {language["fo10"]}
              </a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] "
              ></a>
              <a
                href="#"
                className="text-[#9F9B97] hover:text-[#fff] md:text-[1.2vw] text-[2.3vw] capitalize"
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div className="md:bg-white bg-[#F5C702] p-[2vw] justify-center items-center flex gap-[3vw]">
        <p className="md:text-[1.5vw] text-[2.3vw] text-[#644F41]">
          Copyright © GrandTruck | Designed by
        </p>
        <p className="md:text-[1.5vw] text-[2.3vw] text-[#644F41] md:text-[#FFCD2C]">
          ABBA marketing
        </p>
        <p className="md:text-[1.5vw] text-[2.3vw] text-[#644F41]">
          - Powered by
        </p>
        <p className="md:text-[1.5vw] text-[2.3vw] text-[#644F41] md:text-[#FFCD2C]">
          ABBA
        </p>
      </div>
    </footer>
  );
}

export default Footer;
